<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import PaginationButton from '~/components/pagination/PaginationButton.vue'
import PaginationItem from '~/components/pagination/PaginationItem.vue'
import Mask from '~/enums/mask'

const props = withDefaults(
  defineProps<{
    currentPage?: number
    extremePagesLimit?: number // The number of first and last pages to be displayed.
    hasLoadMoreButton?: boolean
    hasNextButton?: boolean
    isLoadingMore?: boolean
    isOnWhiteBackground?: boolean
    lastPage?: number | null
    nearbyPagesLimit?: number // The number of pages that are displayed around the active page.
    showGoToPageBlock?: boolean
  }>(),
  {
    currentPage: 1,
    extremePagesLimit: 1,
    hasLoadMoreButton: false,
    hasNextButton: false,
    isLoadingMore: false,
    isOnWhiteBackground: true,
    lastPage: 1,
    nearbyPagesLimit: 1,
    showGoToPageBlock: false
  }
)

const emit = defineEmits<{
  (e: 'changePage', x: number): void
  (e: 'onLoadMoreButtonClick'): void
}>()

const maskaOptions = { number: Mask.getMaskaOptions(Mask.Number) }

const pageToGo = ref<number | null>(null)

const hasEllipses = computed(() => pages.value.some((x: number | string): boolean => typeof x === 'string'))
const isGoToPageButtonDisabled = computed(
  () => pageToGo.value === null || Number(pageToGo.value) === 0 || pageToGo.value > localLastPage.value
)
const localLastPage = computed(() => props.lastPage ?? 1)
const pages = computed(() => {
  const { currentPage, extremePagesLimit, nearbyPagesLimit } = props
  const lastPage = localLastPage.value

  const rangeStart = Math.max(1, currentPage - nearbyPagesLimit)
  const rangeEnd = Math.min(lastPage, currentPage + nearbyPagesLimit)

  const pages: (number | string)[] = []

  const addRange = (start: number, end: number): void => {
    pages.push(...Array.from({ length: end - start + 1 }, (_, i: number): number => start + i))
  }

  if (rangeStart > extremePagesLimit + 1) {
    addRange(1, extremePagesLimit)
    pages.push('ellipsis-1')
  } else {
    addRange(1, rangeStart - 1)
  }

  addRange(rangeStart, rangeEnd)

  if (rangeEnd < lastPage - extremePagesLimit) {
    pages.push('ellipsis-2')
    addRange(lastPage - extremePagesLimit + 1, lastPage)
  } else {
    addRange(rangeEnd + 1, lastPage)
  }

  return pages
})

function onGoToPageButtonClick (): void {
  emit('changePage', pageToGo.value!)

  pageToGo.value = null
}
</script>

<template>
  <div v-if="localLastPage > 1">
    <BasicButton
      v-if="hasLoadMoreButton && currentPage < localLastPage"
      class="mb-6"
      color="white"
      is-green-on-hover
      :is-loading="isLoadingMore"
      title="Показать ещё"
      @click="emit('onLoadMoreButtonClick')"
    />

    <BasicButton
      v-if="hasNextButton && currentPage < localLastPage"
      class="mb-6"
      color="white"
      is-green-on-hover
      title="Дальше"
      @click="emit('changePage', currentPage + 1)"
    />

    <div class="flex items-center justify-between lg:justify-center">
      <PaginationButton
        class="mr-2 lg:mr-24"
        icon-name="outlined/navigate-before"
        :is-disabled="currentPage === 1"
        :is-on-white-background="isOnWhiteBackground"
        @click="emit('changePage', currentPage - 1)"
      />

      <div class="-mx-1 -my-0.5 flex list-none flex-wrap items-center justify-center">
        <template v-for="x in pages" :key="x">
          <PaginationItem v-if="typeof x === 'number'" :is-active="currentPage === x" @click="emit('changePage', x)">
            {{ x }}
          </PaginationItem>
          <PaginationItem v-else is-disabled>
            …
          </PaginationItem>
        </template>
      </div>

      <PaginationButton
        class="ml-2 lg:ml-24"
        icon-name="outlined/navigate-next"
        :is-disabled="currentPage >= localLastPage"
        :is-on-white-background="isOnWhiteBackground"
        @click="emit('changePage', currentPage + 1)"
      />
    </div>

    <div v-if="showGoToPageBlock && hasEllipses" class="flex items-center justify-center space-x-2 text-sm">
      <span>Страница</span>
      <input
        v-model.number="pageToGo"
        v-maska="maskaOptions.number"
        class="w-10 rounded-md border border-solid border-main-gray px-1 text-center outline-none"
      >
      <button
        class="font-medium"
        :class="{
          'pointer-events-none text-main-gray': isGoToPageButtonDisabled,
          'hover:text-main-green': !isGoToPageButtonDisabled
        }"
        @click="onGoToPageButtonClick"
      >
        Перейти
      </button>
    </div>
  </div>
</template>
