import constants from '~/constants'
import BankCardType from '~/enums/bank-card-type'
import type {
  BoxberryPickUpPointShippingData, CdekPickupPointShippingData,
  FivePostPickUpPointShippingData,
  RussianPostCourierShippingData,
  RussianPostPickUpPointShippingData,
  ShippingData
} from '~/types/shipping'

enum OrderShippingType {
  LocalPickUp = '1',
  BoxberryPickUpPoint = '2',
  BoxberryCourier = '3',
  DostavistaInsideMkad = '4',
  DostavistaOutsideMkad = '5',
  RussianPostPickUpPoint = '6',
  RussianPostCourier = '7',
  RussianPostInternationalParcel = '8',
  Email = '9',
  FivePostPickUpPoint = '10',
  YandexDeliveryPickUpPoint = '11',
  DalliCourier = '12',
  CdekPickupPoint = '13',
  CdekCourier = '14'
}

const {
  BoxberryCourier,
  BoxberryPickUpPoint,
  CdekCourier,
  CdekPickupPoint,
  DalliCourier,
  DostavistaInsideMkad,
  DostavistaOutsideMkad,
  Email,
  FivePostPickUpPoint,
  LocalPickUp,
  RussianPostCourier,
  RussianPostInternationalParcel,
  RussianPostPickUpPoint,
  YandexDeliveryPickUpPoint
} = OrderShippingType

const bankCardTypeMapping = new Map([
  [BoxberryCourier, BankCardType.Russian],
  [BoxberryPickUpPoint, BankCardType.Any],
  [CdekCourier, BankCardType.Russian],
  [CdekPickupPoint, BankCardType.Russian],
  [DalliCourier, BankCardType.Russian],
  [DostavistaInsideMkad, BankCardType.Russian],
  [DostavistaOutsideMkad, BankCardType.Russian],
  [Email, BankCardType.Any],
  [FivePostPickUpPoint, BankCardType.Russian],
  [LocalPickUp, BankCardType.Russian],
  [RussianPostCourier, BankCardType.Russian],
  [RussianPostInternationalParcel, BankCardType.International],
  [RussianPostPickUpPoint, BankCardType.Russian],
  [YandexDeliveryPickUpPoint, BankCardType.Russian]
])

const courierDescription = 'Курьерская доставка'
const pickupPointDescription = 'Доставка до\xA0пункта выдачи заказов'
const iconDescriptionMapping = new Map([
  [BoxberryCourier, courierDescription],
  [BoxberryPickUpPoint, pickupPointDescription],
  [CdekCourier, courierDescription],
  [CdekPickupPoint, pickupPointDescription],
  [DalliCourier, courierDescription],
  [DostavistaInsideMkad, 'Экспресс-доставка по\xA0Москве\n(в\xA0пределах МКАД)'],
  [DostavistaOutsideMkad, 'Экспресс-доставка по\xA0Москве (за\xA0пределами МКАД) и\xA0Московской области'],
  [Email, 'Email'],
  [FivePostPickUpPoint, pickupPointDescription],
  [LocalPickUp, 'Самовывоз в\xA0Москве'],
  [RussianPostCourier, courierDescription],
  [RussianPostInternationalParcel, 'Международная доставка'],
  [RussianPostPickUpPoint, pickupPointDescription],
  [YandexDeliveryPickUpPoint, pickupPointDescription]
])

const iconNameMapping = new Map([
  [BoxberryCourier, 'shipping-types/boxberry'],
  [BoxberryPickUpPoint, 'shipping-types/boxberry'],
  [CdekCourier, 'shipping-types/cdek'],
  [CdekPickupPoint, 'shipping-types/cdek'],
  [DalliCourier, 'shipping-types/dalli'],
  [DostavistaInsideMkad, 'shipping-types/dostavista'],
  [DostavistaOutsideMkad, 'shipping-types/dostavista'],
  [Email, null],
  [FivePostPickUpPoint, 'shipping-types/five-post'],
  [LocalPickUp, null],
  [RussianPostCourier, 'shipping-types/russian-post'],
  [RussianPostInternationalParcel, 'shipping-types/ems'],
  [RussianPostPickUpPoint, 'shipping-types/russian-post'],
  [YandexDeliveryPickUpPoint, 'shipping-types/yandex-delivery']
])

const nameMapping = new Map([
  [BoxberryCourier, 'Курьерская доставка\xA0— Boxberry'],
  [BoxberryPickUpPoint, 'Доставка до\xA0пункта выдачи заказов\xA0— Boxberry'],
  [CdekCourier, 'Курьерская доставка\xA0— СДЭК'],
  [CdekPickupPoint, 'Доставка до\xA0пункта выдачи заказов\xA0— СДЭК'],
  [DalliCourier, 'Курьерская доставка\xA0— Dalli'],
  [DostavistaInsideMkad, 'Экспресс-доставка по\xA0Москве (в\xA0пределах МКАД)\xA0— Dostavista'],
  [DostavistaOutsideMkad, 'Экспресс-доставка по\xA0Москве (за\xA0пределами МКАД) и\xA0Московской области\xA0— Dostavista'],
  [Email, 'Email'],
  [FivePostPickUpPoint, 'Доставка до\xA0пункта выдачи заказов\xA0— 5Post'],
  [LocalPickUp, 'Самовывоз в\xA0Москве'],
  [RussianPostCourier, 'Курьерская доставка\xA0— Почта России'],
  [RussianPostInternationalParcel, 'Международная доставка\xA0— EMS Почта России'],
  [RussianPostPickUpPoint, 'Доставка до\xA0пункта выдачи заказов\xA0— Почта России'],
  [YandexDeliveryPickUpPoint, 'Доставка до\xA0пункта выдачи заказов\xA0— Яндекс Доставка']
])

namespace OrderShippingType {
  export const sortedMembers = [
    Email,
    LocalPickUp,
    BoxberryPickUpPoint,
    FivePostPickUpPoint,
    CdekPickupPoint,
    YandexDeliveryPickUpPoint,
    RussianPostPickUpPoint,
    BoxberryCourier,
    RussianPostCourier,
    CdekCourier,
    DalliCourier,
    DostavistaInsideMkad,
    DostavistaOutsideMkad,
    RussianPostInternationalParcel
  ]

  export function getBankCardType (orderShippingType: OrderShippingType): BankCardType {
    return bankCardTypeMapping.get(orderShippingType)!
  }

  export function getIconDescription (orderShippingType: OrderShippingType): string {
    return iconDescriptionMapping.get(orderShippingType)!
  }

  export function getIconName (orderShippingType: OrderShippingType): string {
    return iconNameMapping.get(orderShippingType)!
  }

  export function getName (orderShippingType: OrderShippingType): string {
    return nameMapping.get(orderShippingType)!
  }

  export function hasAddress (orderShippingType: OrderShippingType): boolean {
    return [
      BoxberryCourier,
      CdekCourier,
      DostavistaInsideMkad,
      DostavistaOutsideMkad,
      RussianPostInternationalParcel
    ].includes(orderShippingType)
  }

  export function hasShippingData (orderShippingType: OrderShippingType): boolean {
    return [
      BoxberryPickUpPoint,
      CdekCourier,
      CdekPickupPoint,
      DalliCourier,
      FivePostPickUpPoint,
      RussianPostCourier,
      RussianPostInternationalParcel,
      RussianPostPickUpPoint,
      YandexDeliveryPickUpPoint
    ].includes(orderShippingType)
  }

  export function isDeliveryToMoscow (
    orderShippingType: OrderShippingType,
    shippingData: ShippingData | null
  ): boolean | null {
    switch (orderShippingType) {
      case BoxberryPickUpPoint:
        return constants.moscowDivisions.some(
          (x: string): boolean => (shippingData as BoxberryPickUpPointShippingData).address.includes(x)
        )
      case CdekCourier:
        return (shippingData as CdekPickupPointShippingData).fullAddress.includes('Москва')
      case DalliCourier:
        return true // TODO
      case FivePostPickUpPoint:
        return constants.moscowDivisions.some(
          (x: string): boolean => (shippingData as FivePostPickUpPointShippingData).fullAddress.includes(x)
        )
      case LocalPickUp:
        return true
      case RussianPostCourier:
      case RussianPostPickUpPoint:
        return (shippingData as RussianPostCourierShippingData|RussianPostPickUpPointShippingData).region === 'г Москва'
    }

    return null
  }

  export function isDeliveryToPickUpPoint (orderShippingType: OrderShippingType): boolean {
    return [
      BoxberryPickUpPoint,
      CdekPickupPoint,
      FivePostPickUpPoint,
      RussianPostPickUpPoint,
      YandexDeliveryPickUpPoint
    ].includes(orderShippingType)
  }
}

export default OrderShippingType
