enum SearchboosterSort {
  Popular = 'param.Рекомендован',
  PriceAsc = 'price',
  PriceDesc = 'price.desc',
  Rating = 'param.Рейтинг',
  Special = 'param.Скидка%',
}

const {
  Popular,
  PriceAsc,
  PriceDesc,
  Rating,
  Special
} = SearchboosterSort

const nameMapping = new Map([
  [Popular, 'Популярные'],
  [PriceAsc, 'Дешёвые'],
  [PriceDesc, 'Дорогие'],
  [Rating, 'С высоким рейтингом'],
  [Special, 'По акции'],
])

namespace SearchboosterSort {
  export function getName (searchboosterSort: SearchboosterSort): string {
    return nameMapping.get(searchboosterSort)!
  }

  export function getPropertyByValue (value: string): SearchboosterSort | undefined {
    for (const key in SearchboosterSort) {
      if (SearchboosterSort[key as keyof typeof SearchboosterSort] === value) {
        return SearchboosterSort[key as keyof typeof SearchboosterSort]
      }
    }

    return undefined
  }
}

export default SearchboosterSort
