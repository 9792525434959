
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as appqOuFeTWQ_k965Emc69uIrBxwLUmgNKAfVspRCbb4KzYMeta } from "/usr/src/app/pages/app.vue?macro=true";
import { default as faqtbSmvE7t3pW95ldnpctnJVvnzzOUkjWXSpx1Amw10bIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as carthyYcxdn14__45ZgPZs2jukCXA9IF9QtsTw25I52HcOjPkMeta } from "/usr/src/app/pages/cart.vue?macro=true";
import { default as aboutGM5UHk7qsam6f1oSc2Z49RGj4f4aP_rVMkr_45wA6YtokMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as debugjBdJH8Nd_45pMew1j3YyQlgE7Oi3Xb3vw1ZeSeEJAT1O4Meta } from "/usr/src/app/pages/debug.vue?macro=true";
import { default as indexdP7zTFRl7aZq2Pc08cwkTh9_Xhz5atMbl890jb4bLIEMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as returnFZg9nbmOI8_0vckq6FAqZH6DeLqbDbSsdvpcWVGOAAEMeta } from "/usr/src/app/pages/return.vue?macro=true";
import { default as searchd5bcWK5VSxs0dWoz6VXHXK2iZS7pJPGsZTB1hcAycoEMeta } from "/usr/src/app/pages/search.vue?macro=true";
import { default as offline3H3dhy96dYFf_452CAWbEbzpH3Ml5qOpbLA_NQB7_45ZI6gMeta } from "/usr/src/app/pages/offline.vue?macro=true";
import { default as partneriu3ryYX_EZwIo7CsxCmtxxJ11hsdakyPoTzmn97sMbUMeta } from "/usr/src/app/pages/partner.vue?macro=true";
import { default as paymentqoaiMxSOmYXI_c3YwjfzPlYdh7SxYhBlzUFkxVvivvUMeta } from "/usr/src/app/pages/payment.vue?macro=true";
import { default as reviews4wLY8I3Rl1RdZSKXZEkVI46VSbrzW6qJ1wAxcVJAxaEMeta } from "/usr/src/app/pages/reviews.vue?macro=true";
import { default as contacts3d80ERC5JVxlx3LiJWXCUHTWJoHUOPVZgS0PqcnaR3sMeta } from "/usr/src/app/pages/contacts.vue?macro=true";
import { default as delivery9jw8b_45Aodwlc44gp9Uo_lxikvqigA1tJrxFnBRBFGEYMeta } from "/usr/src/app/pages/delivery.vue?macro=true";
import { default as calltouchBHDtMsdACSaWXcxMN30Mqk3bu8Mh9iuf_CkL8PeWPDEMeta } from "/usr/src/app/pages/calltouch.vue?macro=true";
import { default as favoritesQOzDs1SHu_45L_45EBgnnQ3bb9rHA9v9f0edWQFQN55QUSIMeta } from "/usr/src/app/pages/favorites.vue?macro=true";
import { default as tagsqx_45RrHUiKzCNdR6sHpSfTfcEJWvlOYcWp5dqol3V2VcMeta } from "/usr/src/app/pages/admin/tags.vue?macro=true";
import { default as _91id_930U3n0BYkfNWPRa_TeluvDZYOA52yVqbuz7rgVsHGe8oMeta } from "/usr/src/app/pages/lists/[id].vue?macro=true";
import { default as indexheIeQbzM9wLVSuMwWpaQiLiL4W4bdha841uk8k5lUScMeta } from "/usr/src/app/pages/admin/index.vue?macro=true";
import { default as testsMysDVuc8Xe8xl5CmcbGJ8JPQMyJi5a_45pEhjGrLSUoEwMeta } from "/usr/src/app/pages/admin/tests.vue?macro=true";
import { default as usersslT5pu_45mmpu8Xg_45v_45D8xaJ6Q4TdgYKmnKWG7ZruSNRUMeta } from "/usr/src/app/pages/admin/users.vue?macro=true";
import { default as distributorQfgbn1onobrBPfOoI_i_e8kEm8QU94pIq83_fMn7BNEMeta } from "/usr/src/app/pages/distributor.vue?macro=true";
import { default as brandsEnSStql3WCeOe4YW1d0W_k0jtqOZATbsteN2uTTLwAsMeta } from "/usr/src/app/pages/admin/brands.vue?macro=true";
import { default as ordersm_XJrXW_UKAfoNdytG8KJiWQDiC_45lROZu32QXof5glUMeta } from "/usr/src/app/pages/admin/orders.vue?macro=true";
import { default as indexZSzBTxnUa7omEFBPHGSpWRX2Av_45tRwuy3ippfzrehI4Meta } from "/usr/src/app/pages/brands/index.vue?macro=true";
import { default as certificates0J7OiC2WLBhXvdUSFoujixwSjnHL2AR4_45_45XwV0JvsTIMeta } from "/usr/src/app/pages/certificates.vue?macro=true";
import { default as indexQkfZdo6ZiMAGDgtzvRa1sZcdkd0247XFY9i8BP3Ps_45oMeta } from "/usr/src/app/pages/account/index.vue?macro=true";
import { default as lists55ffN_VfatFOYh98oo3IlTH4SQk3CpgwmzneG8vSLLoMeta } from "/usr/src/app/pages/account/lists.vue?macro=true";
import { default as banners2NHNIXcxuv0R4Dgl_IS5WyUlarfDtKNRv7cK625fMLAMeta } from "/usr/src/app/pages/admin/banners.vue?macro=true";
import { default as reviewst0KgHRpEdl48do5oSZzZbQDI_45oIFOZMIrgeD4470AMUMeta } from "/usr/src/app/pages/admin/reviews.vue?macro=true";
import { default as scripts2RdYfMGzoUThUhu_45nN6VmR8KEcTp_zHVb_45Vz7nLL1bYMeta } from "/usr/src/app/pages/admin/scripts.vue?macro=true";
import { default as orders18C6WmilQCPaP6sZUOHUl6AL8o3J6ltmpF5IBuxP_45jcMeta } from "/usr/src/app/pages/account/orders.vue?macro=true";
import { default as articlesokBLhPLJ_451UtEX50jyvZo3wjxWlYSQHjfxrgvlcXk_AMeta } from "/usr/src/app/pages/admin/articles.vue?macro=true";
import { default as messages26mUXO9OZP_McxpPAQd38_45YfHqDRCuHrRvMio6RITVkMeta } from "/usr/src/app/pages/admin/messages.vue?macro=true";
import { default as products5dhcyQ_45tulri1bHmEu6ZYn_CZ_451R4C_wKlg0FBOpJYsMeta } from "/usr/src/app/pages/admin/products.vue?macro=true";
import { default as indexiDQLD4ORuEDVL8zLZpZtmu9m7mlooValU3QVEOCUL0sMeta } from "/usr/src/app/pages/articles/index.vue?macro=true";
import { default as indexMoRJ68d_45GisCGWiVNfTDmv1uh1JPvTCfF28ZHoFDipAMeta } from "/usr/src/app/pages/products/index.vue?macro=true";
import { default as indexAT0Lbgq_JEi0EskU7aMaJ_rGa7g8YrouxabKiRwSWTUMeta } from "/usr/src/app/pages/supplier/index.vue?macro=true";
import { default as analyticsdVxvvDpsgYZGBggZr59jdi8J00IziBQOWu0xqT_45gD7IMeta } from "/usr/src/app/pages/admin/analytics.vue?macro=true";
import { default as dashboardtx6deMYfoKD7h5QYabhFN0OMrIRPz18n3x86zBDRTDEMeta } from "/usr/src/app/pages/admin/dashboard.vue?macro=true";
import { default as suppliersIyOTkhDVM2JDlT6cwZfdDdIDPWA2kdXRQZiAdm8NpsoMeta } from "/usr/src/app/pages/admin/suppliers.vue?macro=true";
import { default as indexx2tFTZGmr9TQ2_45YRI_S_456aW1Jy4A1Rx06kFKOngqbXwMeta } from "/usr/src/app/pages/old-tests/index.vue?macro=true";
import { default as brands92cZJgxULqoz0mtf6KPqu8TntpFC7DzMkIi1FcW2AfYMeta } from "/usr/src/app/pages/supplier/brands.vue?macro=true";
import { default as settingsE3_aBCTfPjk_zAMhD1V_45VGh1QvncSWlA7Uchqeg2MXsMeta } from "/usr/src/app/pages/account/settings.vue?macro=true";
import { default as _91action_93bzLdzsTygsae5bVHKhIdqPhQxSoM_F5bU0XOmIVyO4oMeta } from "/usr/src/app/pages/actions/[action].vue?macro=true";
import { default as categoriesae433Mm8DKJc9amhnAHZNfGw450DQ0iDMDW1ABPAexsMeta } from "/usr/src/app/pages/admin/categories.vue?macro=true";
import { default as warehouses_EUkDXAb8AEjrj8ZDA3li3Cy7_45ISFKVdMIAe4C_45FJx4Meta } from "/usr/src/app/pages/admin/warehouses.vue?macro=true";
import { default as indexSG18i2JiC9RT4aN6xA_oObYMOEzZ2apSzCYY9qLzulEMeta } from "/usr/src/app/pages/specialist/index.vue?macro=true";
import { default as reviewsNQ0KrgCNTaYdk_454ckfH47YB6dfqAGHZHavLOTZLYHtAMeta } from "/usr/src/app/pages/supplier/reviews.vue?macro=true";
import { default as addressesahx6MQsLCm8nCG3uO_45XJKUI68XPdSrJwAgBW4OHyjpYMeta } from "/usr/src/app/pages/account/addresses.vue?macro=true";
import { default as ambassadors9AtNsFXBCpVzGlkkXvN47sB90n2apskgqHnPKstLOukMeta } from "/usr/src/app/pages/admin/ambassadors.vue?macro=true";
import { default as collectionsypRy3quOgdGp_kPp0qV_455UCzcpObyIHShqKsFdBJE3oMeta } from "/usr/src/app/pages/admin/collections.vue?macro=true";
import { default as promo_45codesfQFgMvt95qeqxrgItiHCX6Qc2TgIEO6_45d865vvmAfUwMeta } from "/usr/src/app/pages/admin/promo-codes.vue?macro=true";
import { default as specialistsOQ3jr6zdcnMRh8ZSLjUrwtAvBHe37eOZ3N3TKnGHRvIMeta } from "/usr/src/app/pages/admin/specialists.vue?macro=true";
import { default as withdrawalsG7RuY_kCT47zIip0iAq9Nc3kj39GYSCSVmmw9aVWcr8Meta } from "/usr/src/app/pages/admin/withdrawals.vue?macro=true";
import { default as indexQ9_jODvs5iW8floOLUZAWTpJfxyFW1nJg5XdUjFLpTUMeta } from "/usr/src/app/pages/collections/index.vue?macro=true";
import { default as createJMu9GGpSUQpRvxBkK1LeE4eA1cBD7OVwHlM1vkHRYmwMeta } from "/usr/src/app/pages/specialist/create.vue?macro=true";
import { default as indexWeChvsc2LPUOnfDFe5iEl7KAOurtleCi_M69f4EoWVgMeta } from "/usr/src/app/pages/specialists/index.vue?macro=true";
import { default as productshdejd11B3OF0BxzA2_45zaWiun7Yy_45Tdv9HuVGrOjj8DMMeta } from "/usr/src/app/pages/supplier/products.vue?macro=true";
import { default as certificatesGsJnUVrocAxiOkUkFMOwwKNFTKRSakLQdI9qV8S6WMQMeta } from "/usr/src/app/pages/admin/certificates.vue?macro=true";
import { default as _91slugAndId_938OykbcqPUDbJNH2L8y3gueAcJM_TPbzT7Lz7KSJZPbEMeta } from "/usr/src/app/pages/brands/[slugAndId].vue?macro=true";
import { default as profiledwcVgikEqOeAOR_HogWd3YYF7VIwiOuVSNFEdR656hUMeta } from "/usr/src/app/pages/specialist/profile.vue?macro=true";
import { default as analyticsMz4N55Y5cpr45_45dpr6O2YynAsLfYEPZ8oc2mOgrukqsMeta } from "/usr/src/app/pages/supplier/analytics.vue?macro=true";
import { default as consultationsz_45JvXK09FYszhoLCCj7MGNyncJqFusroUgmtfHmc9wIMeta } from "/usr/src/app/pages/admin/consultations.vue?macro=true";
import { default as supplier_45faqspFpMXj2VLBDOablAf2kvj71SshEu6rKBQEeVGXvASukMeta } from "/usr/src/app/pages/admin/supplier-faqs.vue?macro=true";
import { default as supply_45ordersA0cFZvpgs8FhgEeCiy1AdBIHf0a4PqnL0yno24ItaxEMeta } from "/usr/src/app/pages/admin/supply-orders.vue?macro=true";
import { default as test_45resultsxa3bn1Z5mpNYPPlJuz_am7jHYXmI5fui1AuIOiUX71AMeta } from "/usr/src/app/pages/account/test-results.vue?macro=true";
import { default as special_45offersiQSP3N61dGcE8C1TeSe2c_45LH0kYzkj_X7J_45kRWUA_45WwMeta } from "/usr/src/app/pages/admin/special-offers.vue?macro=true";
import { default as _91slugAndId_937ozGqAR90W_45b_45IJW8_45WRYl8VipGbsV9OZfyfgPHSqTAMeta } from "/usr/src/app/pages/articles/[slugAndId].vue?macro=true";
import { default as _91slugAndId_93I_45798rLOKXg4kdXSnNsf_L5cduQX7nS7djkp68NWJmEMeta } from "/usr/src/app/pages/products/[slugAndId].vue?macro=true";
import { default as indexOhadLxlvrNzlsDQoVwD_pi7_7ZwiWU6vn_45LvRLvkSy0Meta } from "/usr/src/app/pages/special-offers/index.vue?macro=true";
import { default as consultationsAFfBtuC5yWxILEIiUBnqRZkaKH_45_452lNHIUX4QKDnMs8Meta } from "/usr/src/app/pages/account/consultations.vue?macro=true";
import { default as _91id_93bZJntjCpxvE4LcKfAC2UBZAAjnJSrJuYkyCe62AA1_sMeta } from "/usr/src/app/pages/admin/specialist/[id].vue?macro=true";
import { default as _91slugAndId_934xUj_21YCK9jEmY4STMiE1HXsmAuoIscflR0idLe8_45AMeta } from "/usr/src/app/pages/old-tests/[slugAndId].vue?macro=true";
import { default as _91slugAndId_93EGCF5HyG0JVH_4IeycbDBQMCFDDA6X61oBVR_kndsGQMeta } from "/usr/src/app/pages/categories/[slugAndId].vue?macro=true";
import { default as supply_45orderssFgRKYhwS1oMLOpAT_45Fzk7qc15uS8A8iCULEufZf4IUMeta } from "/usr/src/app/pages/supplier/supply-orders.vue?macro=true";
import { default as recommendationsLkEGQT9_CBkOeRTV__KR_9Hje9w_CbfG0JU_s4Be388Meta } from "/usr/src/app/pages/account/recommendations.vue?macro=true";
import { default as product_45questionsdF2gAHPa_BlZetCJLA1DSLslaGIwuG7acwX4U_sMWpwMeta } from "/usr/src/app/pages/admin/product-questions.vue?macro=true";
import { default as createzUuO0cxWTCNYMpo2XpwGqrLHREDKmZk600FwQwnI3wgMeta } from "/usr/src/app/pages/admin/specialist/create.vue?macro=true";
import { default as _91slugAndId_93Uf45slC_8F3TrfoiWJ5hHJmc_qrOtz_45T22Z6q2QR4aMMeta } from "/usr/src/app/pages/collections/[slugAndId].vue?macro=true";
import { default as _91slugAndId_93jTemY3TdGwbdiEO0NSXnRAZYxdzDUkSdXvEkKYpAhYoMeta } from "/usr/src/app/pages/specialists/[slugAndId].vue?macro=true";
import { default as consultationsZP1JQxQBEQ_45CWmB0Ga7tx6y5IhvfWOPEQNYxLboIvJEMeta } from "/usr/src/app/pages/specialist/consultations.vue?macro=true";
import { default as change_45order_45statusnaFGs96v9YUpTB1V7ATixapke9m4muEXbcpSwtq0SeMMeta } from "/usr/src/app/pages/admin/change-order-status.vue?macro=true";
import { default as expert_45medical_45commissionbRzXKYXjTUgMgfH7m20YQ341vOIyXSKfnqzTjbFsJDUMeta } from "/usr/src/app/pages/expert-medical-commission.vue?macro=true";
import { default as quality_45certificatesmPQ6jO8UpJWqzDFs64gnUB8Wh_45WQelTLdAQSfPq72BIMeta } from "/usr/src/app/pages/admin/quality-certificates.vue?macro=true";
import { default as _91slugAndId_93Z5sEyJ24s5Kzj6vnDelm_45Ok8SwKUpqWDoSAQ6_numzYMeta } from "/usr/src/app/pages/special-offers/[slugAndId].vue?macro=true";
import { default as recommendationsH47S46w8csl3Z5oDrKkpQG_OsulNIg2740sF5hlYaY4Meta } from "/usr/src/app/pages/specialist/recommendations.vue?macro=true";
import { default as product_45questionseB8VAIt4eLx_fEfUA1FPzpurVx8BK_45WCUGwAcIYY40cMeta } from "/usr/src/app/pages/supplier/product-questions.vue?macro=true";
import { default as supplier_45applicationsiJCGSPzKryrWfBhWAEjAqHFFj5OqnH_DaoXqfjBFGWQMeta } from "/usr/src/app/pages/admin/supplier-applications.vue?macro=true";
import { default as _91id_93QSOniFgezb3FozMa7utgBnFG5X8ydjG8cU0Y5I8j04sMeta } from "/usr/src/app/pages/specialist/appointment/[id].vue?macro=true";
import { default as promo_45code_45applicationsYC2CCz7Ae6ld8ly8_Cs2kwjnzqKA_D8Tqfh_45CpcpKFsMeta } from "/usr/src/app/pages/admin/promo-code-applications.vue?macro=true";
import { default as quality_45certificatesRVzrKz4LHtw74rABjfxyzAGH5zevYJKvTEhqCq7Bu2gMeta } from "/usr/src/app/pages/supplier/quality-certificates.vue?macro=true";
import { default as _91id_93HPAhFY6LWZN5Nw3097ufOCd8NF89PxE_45lwEDd3qsLPEMeta } from "/usr/src/app/pages/specialist/successful-appointment/[id].vue?macro=true";
export default [
  {
    name: "app",
    path: "/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/usr/src/app/pages/faq.vue")
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/usr/src/app/pages/cart.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "debug",
    path: "/debug",
    component: () => import("/usr/src/app/pages/debug.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "return",
    path: "/return",
    component: () => import("/usr/src/app/pages/return.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/usr/src/app/pages/search.vue")
  },
  {
    name: "offline",
    path: "/offline",
    component: () => import("/usr/src/app/pages/offline.vue")
  },
  {
    name: "partner",
    path: "/partner",
    component: () => import("/usr/src/app/pages/partner.vue")
  },
  {
    name: "payment",
    path: "/payment",
    component: () => import("/usr/src/app/pages/payment.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    component: () => import("/usr/src/app/pages/reviews.vue")
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/usr/src/app/pages/contacts.vue")
  },
  {
    name: "delivery",
    path: "/delivery",
    component: () => import("/usr/src/app/pages/delivery.vue")
  },
  {
    name: "calltouch",
    path: "/calltouch",
    component: () => import("/usr/src/app/pages/calltouch.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/usr/src/app/pages/favorites.vue")
  },
  {
    name: "admin-tags",
    path: "/admin/tags",
    component: () => import("/usr/src/app/pages/admin/tags.vue")
  },
  {
    name: "lists-id",
    path: "/lists/:id()",
    component: () => import("/usr/src/app/pages/lists/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/usr/src/app/pages/admin/index.vue")
  },
  {
    name: "admin-tests",
    path: "/admin/tests",
    component: () => import("/usr/src/app/pages/admin/tests.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/usr/src/app/pages/admin/users.vue")
  },
  {
    name: "distributor",
    path: "/distributor",
    component: () => import("/usr/src/app/pages/distributor.vue")
  },
  {
    name: "admin-brands",
    path: "/admin/brands",
    component: () => import("/usr/src/app/pages/admin/brands.vue")
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    component: () => import("/usr/src/app/pages/admin/orders.vue")
  },
  {
    name: "brands",
    path: "/brands",
    component: () => import("/usr/src/app/pages/brands/index.vue")
  },
  {
    name: "certificates",
    path: "/certificates",
    component: () => import("/usr/src/app/pages/certificates.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/usr/src/app/pages/account/index.vue")
  },
  {
    name: "account-lists",
    path: "/account/lists",
    component: () => import("/usr/src/app/pages/account/lists.vue")
  },
  {
    name: "admin-banners",
    path: "/admin/banners",
    component: () => import("/usr/src/app/pages/admin/banners.vue")
  },
  {
    name: "admin-reviews",
    path: "/admin/reviews",
    component: () => import("/usr/src/app/pages/admin/reviews.vue")
  },
  {
    name: "admin-scripts",
    path: "/admin/scripts",
    component: () => import("/usr/src/app/pages/admin/scripts.vue")
  },
  {
    name: "account-orders",
    path: "/account/orders",
    component: () => import("/usr/src/app/pages/account/orders.vue")
  },
  {
    name: "admin-articles",
    path: "/admin/articles",
    component: () => import("/usr/src/app/pages/admin/articles.vue")
  },
  {
    name: "admin-messages",
    path: "/admin/messages",
    component: () => import("/usr/src/app/pages/admin/messages.vue")
  },
  {
    name: "admin-products",
    path: "/admin/products",
    component: () => import("/usr/src/app/pages/admin/products.vue")
  },
  {
    name: "articles",
    path: "/articles",
    component: () => import("/usr/src/app/pages/articles/index.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/usr/src/app/pages/products/index.vue")
  },
  {
    name: "supplier",
    path: "/supplier",
    component: () => import("/usr/src/app/pages/supplier/index.vue")
  },
  {
    name: "admin-analytics",
    path: "/admin/analytics",
    component: () => import("/usr/src/app/pages/admin/analytics.vue")
  },
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    component: () => import("/usr/src/app/pages/admin/dashboard.vue")
  },
  {
    name: "admin-suppliers",
    path: "/admin/suppliers",
    component: () => import("/usr/src/app/pages/admin/suppliers.vue")
  },
  {
    name: "old-tests",
    path: "/old-tests",
    component: () => import("/usr/src/app/pages/old-tests/index.vue")
  },
  {
    name: "supplier-brands",
    path: "/supplier/brands",
    component: () => import("/usr/src/app/pages/supplier/brands.vue")
  },
  {
    name: "account-settings",
    path: "/account/settings",
    component: () => import("/usr/src/app/pages/account/settings.vue")
  },
  {
    name: "actions-action",
    path: "/actions/:action()",
    component: () => import("/usr/src/app/pages/actions/[action].vue")
  },
  {
    name: "admin-categories",
    path: "/admin/categories",
    component: () => import("/usr/src/app/pages/admin/categories.vue")
  },
  {
    name: "admin-warehouses",
    path: "/admin/warehouses",
    component: () => import("/usr/src/app/pages/admin/warehouses.vue")
  },
  {
    name: "specialist",
    path: "/specialist",
    component: () => import("/usr/src/app/pages/specialist/index.vue")
  },
  {
    name: "supplier-reviews",
    path: "/supplier/reviews",
    component: () => import("/usr/src/app/pages/supplier/reviews.vue")
  },
  {
    name: "account-addresses",
    path: "/account/addresses",
    component: () => import("/usr/src/app/pages/account/addresses.vue")
  },
  {
    name: "admin-ambassadors",
    path: "/admin/ambassadors",
    component: () => import("/usr/src/app/pages/admin/ambassadors.vue")
  },
  {
    name: "admin-collections",
    path: "/admin/collections",
    component: () => import("/usr/src/app/pages/admin/collections.vue")
  },
  {
    name: "admin-promo-codes",
    path: "/admin/promo-codes",
    component: () => import("/usr/src/app/pages/admin/promo-codes.vue")
  },
  {
    name: "admin-specialists",
    path: "/admin/specialists",
    component: () => import("/usr/src/app/pages/admin/specialists.vue")
  },
  {
    name: "admin-withdrawals",
    path: "/admin/withdrawals",
    component: () => import("/usr/src/app/pages/admin/withdrawals.vue")
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/usr/src/app/pages/collections/index.vue")
  },
  {
    name: "specialist-create",
    path: "/specialist/create",
    component: () => import("/usr/src/app/pages/specialist/create.vue")
  },
  {
    name: "specialists",
    path: "/specialists",
    meta: indexWeChvsc2LPUOnfDFe5iEl7KAOurtleCi_M69f4EoWVgMeta || {},
    component: () => import("/usr/src/app/pages/specialists/index.vue")
  },
  {
    name: "supplier-products",
    path: "/supplier/products",
    component: () => import("/usr/src/app/pages/supplier/products.vue")
  },
  {
    name: "admin-certificates",
    path: "/admin/certificates",
    component: () => import("/usr/src/app/pages/admin/certificates.vue")
  },
  {
    name: "brands-slugAndId",
    path: "/brands/:slugAndId()",
    component: () => import("/usr/src/app/pages/brands/[slugAndId].vue")
  },
  {
    name: "specialist-profile",
    path: "/specialist/profile",
    meta: profiledwcVgikEqOeAOR_HogWd3YYF7VIwiOuVSNFEdR656hUMeta || {},
    component: () => import("/usr/src/app/pages/specialist/profile.vue")
  },
  {
    name: "supplier-analytics",
    path: "/supplier/analytics",
    component: () => import("/usr/src/app/pages/supplier/analytics.vue")
  },
  {
    name: "admin-consultations",
    path: "/admin/consultations",
    component: () => import("/usr/src/app/pages/admin/consultations.vue")
  },
  {
    name: "admin-supplier-faqs",
    path: "/admin/supplier-faqs",
    component: () => import("/usr/src/app/pages/admin/supplier-faqs.vue")
  },
  {
    name: "admin-supply-orders",
    path: "/admin/supply-orders",
    component: () => import("/usr/src/app/pages/admin/supply-orders.vue")
  },
  {
    name: "account-test-results",
    path: "/account/test-results",
    component: () => import("/usr/src/app/pages/account/test-results.vue")
  },
  {
    name: "admin-special-offers",
    path: "/admin/special-offers",
    component: () => import("/usr/src/app/pages/admin/special-offers.vue")
  },
  {
    name: "articles-slugAndId",
    path: "/articles/:slugAndId()",
    component: () => import("/usr/src/app/pages/articles/[slugAndId].vue")
  },
  {
    name: "products-slugAndId",
    path: "/products/:slugAndId()",
    component: () => import("/usr/src/app/pages/products/[slugAndId].vue")
  },
  {
    name: "special-offers",
    path: "/special-offers",
    component: () => import("/usr/src/app/pages/special-offers/index.vue")
  },
  {
    name: "account-consultations",
    path: "/account/consultations",
    meta: consultationsAFfBtuC5yWxILEIiUBnqRZkaKH_45_452lNHIUX4QKDnMs8Meta || {},
    component: () => import("/usr/src/app/pages/account/consultations.vue")
  },
  {
    name: "admin-specialist-id",
    path: "/admin/specialist/:id()",
    component: () => import("/usr/src/app/pages/admin/specialist/[id].vue")
  },
  {
    name: "old-tests-slugAndId",
    path: "/old-tests/:slugAndId()",
    component: () => import("/usr/src/app/pages/old-tests/[slugAndId].vue")
  },
  {
    name: "categories-slugAndId",
    path: "/categories/:slugAndId()",
    component: () => import("/usr/src/app/pages/categories/[slugAndId].vue")
  },
  {
    name: "supplier-supply-orders",
    path: "/supplier/supply-orders",
    component: () => import("/usr/src/app/pages/supplier/supply-orders.vue")
  },
  {
    name: "account-recommendations",
    path: "/account/recommendations",
    component: () => import("/usr/src/app/pages/account/recommendations.vue")
  },
  {
    name: "admin-product-questions",
    path: "/admin/product-questions",
    component: () => import("/usr/src/app/pages/admin/product-questions.vue")
  },
  {
    name: "admin-specialist-create",
    path: "/admin/specialist/create",
    component: () => import("/usr/src/app/pages/admin/specialist/create.vue")
  },
  {
    name: "collections-slugAndId",
    path: "/collections/:slugAndId()",
    component: () => import("/usr/src/app/pages/collections/[slugAndId].vue")
  },
  {
    name: "specialists-slugAndId",
    path: "/specialists/:slugAndId()",
    meta: _91slugAndId_93jTemY3TdGwbdiEO0NSXnRAZYxdzDUkSdXvEkKYpAhYoMeta || {},
    component: () => import("/usr/src/app/pages/specialists/[slugAndId].vue")
  },
  {
    name: "specialist-consultations",
    path: "/specialist/consultations",
    meta: consultationsZP1JQxQBEQ_45CWmB0Ga7tx6y5IhvfWOPEQNYxLboIvJEMeta || {},
    component: () => import("/usr/src/app/pages/specialist/consultations.vue")
  },
  {
    name: "admin-change-order-status",
    path: "/admin/change-order-status",
    component: () => import("/usr/src/app/pages/admin/change-order-status.vue")
  },
  {
    name: "expert-medical-commission",
    path: "/expert-medical-commission",
    component: () => import("/usr/src/app/pages/expert-medical-commission.vue")
  },
  {
    name: "admin-quality-certificates",
    path: "/admin/quality-certificates",
    component: () => import("/usr/src/app/pages/admin/quality-certificates.vue")
  },
  {
    name: "special-offers-slugAndId",
    path: "/special-offers/:slugAndId()",
    component: () => import("/usr/src/app/pages/special-offers/[slugAndId].vue")
  },
  {
    name: "specialist-recommendations",
    path: "/specialist/recommendations",
    component: () => import("/usr/src/app/pages/specialist/recommendations.vue")
  },
  {
    name: "supplier-product-questions",
    path: "/supplier/product-questions",
    component: () => import("/usr/src/app/pages/supplier/product-questions.vue")
  },
  {
    name: "admin-supplier-applications",
    path: "/admin/supplier-applications",
    component: () => import("/usr/src/app/pages/admin/supplier-applications.vue")
  },
  {
    name: "specialist-appointment-id",
    path: "/specialist/appointment/:id()",
    meta: _91id_93QSOniFgezb3FozMa7utgBnFG5X8ydjG8cU0Y5I8j04sMeta || {},
    component: () => import("/usr/src/app/pages/specialist/appointment/[id].vue")
  },
  {
    name: "admin-promo-code-applications",
    path: "/admin/promo-code-applications",
    component: () => import("/usr/src/app/pages/admin/promo-code-applications.vue")
  },
  {
    name: "supplier-quality-certificates",
    path: "/supplier/quality-certificates",
    component: () => import("/usr/src/app/pages/supplier/quality-certificates.vue")
  },
  {
    name: "specialist-successful-appointment-id",
    path: "/specialist/successful-appointment/:id()",
    meta: _91id_93HPAhFY6LWZN5Nw3097ufOCd8NF89PxE_45lwEDd3qsLPEMeta || {},
    component: () => import("/usr/src/app/pages/specialist/successful-appointment/[id].vue")
  }
]