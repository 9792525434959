import type YandexMetrikaGoal from '~/enums/yandex-metrika-goal'

/**
 * Class YandexMetrikaService
 *
 * @see https://yandex.ru/support/metrica/index.html
 */
export default class YandexMetrikaService {
  private static readonly counterFunctionName = 'yaCounter85685345'

  static reachGoal (yandexMetrikaGoal: YandexMetrikaGoal): void {
    if (!Object.hasOwn(window, this.counterFunctionName)) {
      return
    }

    try {
      window[this.counterFunctionName].reachGoal(yandexMetrikaGoal)
    } catch (e) {
      console.error(e)
    }
  }

  static sendMetricEvent (yandexMetrikaGoal: YandexMetrikaGoal, params?: Record<string, any>): void {
    if (typeof window !== 'undefined' && window.ym) {
      try {
        window.ym(this.counterFunctionName, 'reachGoal', yandexMetrikaGoal.toString(), params)
      } catch (error) {
        console.error('Ошибка при отправке события в Яндекс.Метрику: ', error)
      }
    } else {
      console.warn('Метрика не подключена.')
    }
  }
}
