import { getAuthHeaders } from '~/api/helpers'
import HttpFactory from '~/api/http-factory'
import type {
  CreateCustomCertificateImageRequestData,
  CreateCustomCertificateImageResponse
} from '~/types/custom-certificate-images'

export default class CustomCertificateImages extends HttpFactory {
  private readonly resource = 'v1/customCertificateImages'

  public createCustomCertificateImage (
    data: CreateCustomCertificateImageRequestData
  ): Promise<CreateCustomCertificateImageResponse> {
    return this.$fetch(
      this.resource,
      {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json', ...getAuthHeaders(this.authStore.token) }
      }
    )
  }
}
