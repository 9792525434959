import { defineStore } from 'pinia'

import type UserPermission from '~/enums/user-permission'
import UserRole from '~/enums/user-role'
import helpers from '~/helpers'
import JsonApiHelper from '~/helpers/json-api-helper'

export const messages = {
  failedToGetUser: 'Не удалось получить пользователя.',
  failedToGetUsers: 'Не удалось получить пользователей.'
}

type State = {
  filter: {
    query: string
  }
  isDataLoading: boolean
  page: {
    number: number
    size: number
  }
  permissions: UserPermission[]
  roles: UserRole[]
  totalPages: number | null
  totalUsers: number | null
  userData: any
  usersData: any[]
}

const initialState = (): State => ({
  filter: {
    query: ''
  },
  isDataLoading: true,
  page: {
    number: 1,
    size: 20
  },
  permissions: [],
  roles: [],
  totalPages: null,
  totalUsers: null,
  userData: null,
  usersData: []
})

export const useUserStore = defineStore('user', {
  state: () => initialState(),

  getters: {
    getUser: (state: State) => state.userData,
    getUsers: (state: State) => state.usersData,
    hasAccessToAdminPanel (state: State): boolean {
      for (const role of state.roles) {
        if (UserRole.hasAccessToAdminPanel(role)) {
          return true
        }
      }

      return false
    },
    hasPermission: (state: State) => (permission: UserPermission): boolean => {
      return state.permissions.includes(permission)
    },
    isSpecialist (state: State): boolean {
      return state.roles.includes(UserRole.Specialist)
    },
    isSupplier (state: State): boolean {
      return state.roles.includes(UserRole.Supplier)
    }
  },

  actions: {
    clearFilter (): void {
      this.filter.query = ''
    },
    clearUsers (): void {
      this.usersData = []
    },
    dispose (): void {
      this.clearFilter()
      this.clearUsers()
    },
    initializeStore (): void {
    },
    updateFromUserData (userData: any): void {
      const { attributes, meta } = userData
      const { roleIds } = attributes
      const { permissions } = meta

      if (Array.isArray(permissions)) {
        this.permissions = permissions
      }

      if (Array.isArray(roleIds)) {
        this.roles = roleIds
      }
    },
    async fetchUser (id?: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        const response = await $apiHelper.users.getUser(id, {}) as any
        const { data } = JsonApiHelper.denormalizeResponse(response) as any

        this.userData = data
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToGetUser)
      }
    },
    async fetchUsers (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()
      const params = helpers.getParams([
        { name: 'filter[query]', value: this.filter.query, condition: this.filter.query },
        { name: 'page[number]', value: this.page.number, condition: true },
        { name: 'page[size]', value: this.page.size, condition: true },
      ])

      this.isDataLoading = true
      this.totalPages = null

      try {
        const response = await $apiHelper.users.getUsers(params) as any
        const { data, meta } = JsonApiHelper.denormalizeResponse(response) as any

        this.totalUsers = meta.totalUsers
        this.totalPages = helpers.getTotalPages(meta.totalUsers, this.page.size)

        this.usersData = data

        this.isDataLoading = false
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToGetUsers)
      }
    }
  }
})
