import { stringify } from 'qs'

import Api from '~/api'
import Page from '~/enums/page'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'
import { useUserStore } from '~/store/user'

export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore()
  const productsStore = useProductsStore()
  const userStore = useUserStore()
  const baseURL = import.meta.server && !import.meta.dev
    ? nuxtApp.$config.ssrApiBaseUrl
    : nuxtApp.$config.public.apiBaseUrl

  const apiFetcher = $fetch.create({
    baseURL,
    onRequest ({ options }) {
      if (options.params) {
        options.params = Object.fromEntries(new URLSearchParams(stringify(options.params)))
      }

      if (import.meta.server) {
        const ipAddress = useRequestHeader('X-Forwarded-For')

        if (ipAddress) {
          options.headers.set('X-Forwarded-For', ipAddress)
        }
      }

      const mindboxDeviceUuid = useCookie('mindboxDeviceUUID').value

      if (mindboxDeviceUuid) {
        options.headers.set('X-Mindbox-Device-UUID', mindboxDeviceUuid)
      }
    },
    onResponseError ({ response }) {
      if ([401, 403].includes(response.status)) {
        useCookie('token').value = null
        useCookie('userId').value = null

        authStore.$reset()
        productsStore.$reset()
        userStore.$reset()

        navigateTo(Page.Main)
      }
    }
  })

  return {
    provide: {
      apiHelper: new Api(apiFetcher, authStore)
    }
  }
})
