<script lang="ts" setup>
import ProductCard from '~/components/cards/ProductCard.vue'
import BasicButton from '~/components/form-elements/BasicButton.vue'
import ViewAllButton from '~/components/form-elements/ViewAllButton.vue'
import constants from '~/constants'
import Page from '~/enums/page'
import type { ProductData } from '~/types/products'

const props = withDefaults(
  defineProps<{
    isConsultationsSection?: boolean
    path?: string | null
    productsData: ProductData[]
    queryParameters?: Record<string, string>
    subtitle?: string | null
    title?: string | null
  }>(),
  {
    isConsultationsSection: false,
    path: null,
    queryParameters: () => ({}),
    subtitle: null,
    title: null
  }
)

const route = useRoute()
const router = useRouter()

const allProductsPath = computed(() => {
  const { path, queryParameters } = props

  if (!path) {
    return null
  }

  const queryString = (new URLSearchParams(queryParameters)).toString()

  return `${path}${queryString ? `?${queryString}` : ''}`
})

function goToAllProducts (): void {
  if (typeof allProductsPath.value === 'string') {
    router.push(allProductsPath.value)
  }
}

async function goToPartnerPage (): Promise<void> {
  await router.push(Page.Partner)
}
</script>

<template>
  <section v-if="productsData.length">
    <div class="container">
      <Heading v-if="title" element="h2" :path="path" :title="title" title-class="h1">
        <template v-if="path" #buttons>
          <ViewAllButton title="Смотреть все" @click="goToAllProducts" />
        </template>
      </Heading>

      <div v-if="subtitle" class="mb-6">
        {{ subtitle }}
      </div>
    </div>

    <Gallery :options="constants.galleryOptions">
      <ProductCard
        v-for="x in productsData"
        :key="x.id"
        class="swiper-slide max-5-slides"
        :product-data="x"
      />
    </Gallery>

    <div v-if="isConsultationsSection && route.path !== Page.Main" class="container mt-6">
      <BasicButton color="green" title="Стать специалистом" @click="goToPartnerPage" />
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use 'assets/css/gallery';
</style>
