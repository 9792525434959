import type { StatusColor } from '~/types'

enum ProductModerationStatus {
  Preparation = '1',
  Pending1 = '2',
  Pending2 = '3',
  Approved = '4',
  Rejected = '5'
}

const { Approved, Pending1, Pending2, Preparation, Rejected } = ProductModerationStatus

const colorMapping: Map<ProductModerationStatus, StatusColor> = new Map([
  [Approved, 'green'],
  [Pending1, 'orange'],
  [Pending2, 'orange'],
  [Preparation, 'yellow'],
  [Rejected, 'red']
])

const nameMapping = new Map([
  [Approved, 'Проверен'],
  [Pending1, 'Первичная проверка'],
  [Pending2, 'Повторная проверка'],
  [Preparation, 'Подготовка к проверке'],
  [Rejected, 'Отклонён']
])

namespace ProductModerationStatus {
  export const sortedMembers = [Preparation, Pending1, Pending2, Approved, Rejected]

  export function getColor (productModerationStatus: ProductModerationStatus): StatusColor {
    return colorMapping.get(productModerationStatus)!
  }

  export function getName (productModerationStatus: ProductModerationStatus): string {
    return nameMapping.get(productModerationStatus)!
  }
}

export default ProductModerationStatus
