import constants from '~/constants'
import YandexMetrikaGoal from '~/enums/yandex-metrika-goal'
import EcommerceApiService from '~/services/ecommerce-api-service'
import YandexMetrikaService from '~/services/yandex-metrika-service'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'
import { useSearchboosterStore } from '~/store/searchbooster'
import type { CartProductData } from '~/types/cart'
import type { ProductData } from '~/types/products'

const messages = {
  // Cart.
  failedToAddProductToCart: 'Не удалось добавить товар в корзину.',
  failedToUpdateCartProductQuantity: 'Не удалось изменить количество товара в корзине.',
  productIsAddedToCart: 'Товар добавлен в корзину.',

  // Wish list.
  failedToAddProductToWishList: 'Не удалось добавить товар в «Избранное».',
  failedToDeleteProductFromWishList: 'Не удалось удалить товар из «Избранного».',
  productIsAddedToWishList: 'Товар добавлен в «Избранное».',
  productIsDeletedFromWishList: 'Товар удалён из «Избранного».'
}

export function useProduct () {
  const { $apiHelper, $toast } = useNuxtApp()
  const authStore = useAuthStore()
  const productsStore = useProductsStore()

  async function addProductToCart (productId: number | string, quantity = 1): Promise<void> {
    const searchboosterStore = useSearchboosterStore()
    const { isRecentUpdate } = storeToRefs(searchboosterStore)

    if (typeof productId === 'number') {
      productId = String(productId)
    }

    const { failedToAddProductToCart, productIsAddedToCart } = messages

    productsStore.incrementCartTotalItems(quantity)

    try {
      if (!authStore.isUser) {
        await createGuestIfNeeded()
      }

      await $apiHelper.cartProducts.createCartProduct(productId, quantity)

      productsStore.setCartProductQuantity({ id: productId, quantity })

      $toast.success(productIsAddedToCart)

      EcommerceApiService.addToCart(productId, quantity)
      YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.AddToCart, { product_id: productId })

      if (isRecentUpdate.value) {
        YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.SearchToCart, { product_id: productId })
      }
    } catch (error) {
      console.error(error)

      productsStore.decrementCartTotalItems(quantity)

      $toast.error(failedToAddProductToCart)
    }
  }

  async function createGuestIfNeeded (): Promise<void> {
    const guestIdCookie = useCookie('guestId', constants.cookieOptions)

    if (!guestIdCookie.value) {
      const { data: { id: guestId } } = await $apiHelper.guests.createGuest() as any

      guestIdCookie.value = guestId

      authStore.setGuestId(guestId)
    }
  }

  async function setCartProductQuantity (productId: string, quantity = 1): Promise<void> {
    const currentQuantity = productsStore.getCartProductQuantity(productId)

    if (currentQuantity === null) {
      await addProductToCart(productId)

      return
    }

    const deltaQuantity = quantity - currentQuantity

    if (deltaQuantity === 0) {
      return
    }

    if (deltaQuantity > 0) {
      productsStore.incrementCartTotalItems(deltaQuantity)
    } else {
      productsStore.decrementCartTotalItems(-deltaQuantity)
    }

    try {
      if (quantity) {
        await $apiHelper.cartProducts.updateCartProduct(productId, quantity)

        productsStore.setCartProductQuantity({ id: productId, quantity })
      } else {
        await $apiHelper.cartProducts.deleteCartProduct(productId)

        productsStore.removeCartProduct(productId)
      }
    } catch (error) {
      console.error(error)

      if (deltaQuantity > 0) {
        productsStore.decrementCartTotalItems(deltaQuantity)
      } else {
        productsStore.incrementCartTotalItems(-deltaQuantity)
      }

      $toast.error(messages.failedToUpdateCartProductQuantity)
    }
  }

  async function toggleIsInWishList (productData: ProductData | CartProductData): Promise<void> {
    const {
      failedToAddProductToWishList,
      failedToDeleteProductFromWishList,
      productIsAddedToWishList,
      productIsDeletedFromWishList
    } = messages
    const { id, meta } = productData
    const { isInWishList } = meta

    meta.isInWishList = !isInWishList

    if (isInWishList) {
      productsStore.decrementWishListTotalItems()
    } else {
      productsStore.incrementWishListTotalItems()
    }

    try {
      if (!authStore.isUser) {
        await createGuestIfNeeded()
      }

      if (isInWishList) {
        await $apiHelper.wishListProducts.deleteWishListProduct(id)
      } else {
        await $apiHelper.wishListProducts.createWishListProduct(id)
      }

      $toast.success(isInWishList ? productIsDeletedFromWishList : productIsAddedToWishList)
    } catch (error) {
      console.error(error)

      meta.isInWishList = isInWishList

      if (isInWishList) {
        productsStore.incrementWishListTotalItems()
      } else {
        productsStore.decrementWishListTotalItems()
      }

      $toast.error(isInWishList ? failedToDeleteProductFromWishList : failedToAddProductToWishList)
    }
  }

  return { addProductToCart, createGuestIfNeeded, setCartProductQuantity, toggleIsInWishList }
}
