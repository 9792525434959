import ProductModerationStatus from '~/enums/product-moderation-status'
import ProductStatus from '~/enums/product-status'
import type { ProductData } from '~/types/products'
import type { SearchboosterOffer, SearchboosterSearchBoxItem } from '~/types/searchbooster'

export function searchboosterSearchboxAdapter () {
  return {
    convertItemToProductData (item: SearchboosterSearchBoxItem): ProductData {
      return {
        id: item.id || '',
        type: 'products',
        attributes: {
          descriptionHtml: item.snippet || '',
          heightInMm: null,
          ingredients: null,
          isExpiring: false,
          isForbiddenToSupply: false,
          isHalal: false,
          isOnlyAvailableInMoscow: false,
          isOnPreOrder: false,
          lengthInMm: null,
          moderationStatusId: ProductModerationStatus.Approved,
          multiplicity: null,
          name: item.text?.value,
          oldPrice: null,
          oneCId: null,
          rating: item.rating || null,
          price: item.price || null,
          priceWithPromoCode: null,
          seoData: {},
          slug: '',
          statusId: ProductStatus.Published,
          stock: null,
          totalPublishedReviews: 0,
          weightInGrams: null,
          widthInMm: null,
        },
        relationships: {
          brand: {
            data: null,
          },
          categories: {
            data: item.categories_ids?.map((id) => ({ id, type: 'categories' })) || [],
          },
          facts: {
            data: [],
          },
          images: {
            data: item.image_url ? [{ meta: { type: 'original', url: item.image_url }, type: 'original' }] : []
          },
          researches: {
            data: [],
          },
          supplier: {
            data: null,
          },
          productQuestions: {
            data: [],
          },
          tags: {
            data: [],
          },
        },
        meta: {
          activeIngredientsIds: [],
          attributes: [],
          bodySystemsIds: [],
          discountInKopecks: null,
          isExpiring: false,
          isOnPreOrder: false,
          isOutOfStock: !item.offer?.available,
          isPurchasedByUser: false,
          isHalal: false,
          isInWishList: false,
          labels: [],
          userReviewId: null,
          variations: [],
        }
      }
    },
    convertOfferToProductData (offer: SearchboosterOffer): ProductData {
      const labels = [];

      if (offer.params?.Хит_продаж === 'true') {
        labels.push({
          backgroundColor: '#fbf7eb',
          color: '#d4af37',
          title: 'Хит продаж'
        })
      }

      if (offer.params?.Ч_рная_пятница === 'true') {
        labels.push({
          backgroundColor: '#000000',
          color: '#ffffff',
          title: 'Чёрная пятница'
        })
      }

      if (offer.params?.Рекомендован === 'true') {
        labels.push({
          backgroundColor: '#eef7eb',
          color: '#59ad3b',
          title: 'Рекомендуем'
        })
      }

      return {
        id: offer.id || '',
        type: 'products',
        attributes: {
          descriptionHtml: '',
          heightInMm: null,
          ingredients: null,
          isExpiring: offer.params?.isExpiring === 'true',
          isForbiddenToSupply: false,
          isHalal: false,
          isOnlyAvailableInMoscow: false,
          isOnPreOrder: false,
          lengthInMm: null,
          moderationStatusId: ProductModerationStatus.Approved,
          multiplicity: null,
          name: offer.name,
          oldPrice: null,
          oneCId: null,
          rating: null,
          price: offer.price,
          priceWithPromoCode: offer.params?.priceWithPromoCode,
          seoData: {},
          slug: '',
          statusId: ProductStatus.Published,
          stock: offer.available ? 1 : 0, // TODO: ...
          totalPublishedReviews: 0,
          weightInGrams: null,
          widthInMm: null,
        },
        relationships: {
          brand: {
            data: {
              id: offer.vendor,
              type: 'brands',
            },
          },
          categories: {
            data: offer.categories.map((category: any) => ({ id: category.id, type: 'categories' })),
          },
          facts: {
            data: [],
          },
          images: {
            data: offer.pictures.map((url: any) => ({ meta: { type: 'original', url }, type: 'original' })),
          },
          researches: {
            data: [],
          },
          supplier: {
            data: null,
          },
          productQuestions: {
            data: [],
          },
          tags: {
            data: [],
          },
        },
        meta: {
          activeIngredientsIds: [],
          attributes: [],
          bodySystemsIds: [],
          discountInKopecks: null,
          isExpiring: offer.params?.isExpiring === 'true',
          isOnPreOrder: false,
          isOutOfStock: !offer.available,
          isPurchasedByUser: false,
          isHalal: false,
          isInWishList: false,
          labels: labels,
          userReviewId: null,
          variations: [],
        }
      }
    }
  }
}
