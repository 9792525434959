import type { SwiperOptions } from 'swiper'

import Page from '~/enums/page'

const cdnUrl = 'https://cdn.itab.pro'
const storageBaseUrl = 'https://api.itab.pro/storage/'

export default {
  appUrls: {
    appStore: 'https://apps.apple.com/app/id6615084071',
    googlePlay: 'https://play.google.com/store/apps/details?id=com.ywecom.itab'
  },
  boxberryIntegrationKey: '1$vt6Ti58bdMnYcFA4gYVFtTnHfFFYnd5m',
  categories: {
    againstSars: { id: '188', name: 'Против ОРВИ', path: `${Page.Categories}protiv-orvi-188/` },
    backToSchool: { id: '194', name: 'BACK TO SCHOOL', path: `${Page.Categories}back-to-school-194/` },
    beautyWeek: { id: '219', name: 'Неделя красоты', path: `${Page.Categories}nedelya-krasoty-219/` },
    beautyAndFashion: { id: '228', name: 'Beauty & Fashion', path: `${Page.Categories}beauty-fashion-228/` },
    bestForChildren: { id: '223', name: 'Лучшее детям', path: `${Page.Categories}luchshee-detyam-223/` },
    blackFriday: { id: '77', name: 'ЧЁРНАЯ ПЯТНИЦА', path: `${Page.Categories}chyornaya-pyatnitsa-77/` },
    certificates: { id: '147', name: 'Сертификаты', path: `${Page.Categories}sertifikaty-147/` },
    consultations: { id: '187', name: 'Консультации', path: Page.Specialists },
    detoxWeek: { id: '221', name: 'Детокс-неделя', path: `${Page.Categories}detoks-nedelya-221/` },
    forAnimals: { id: '179', name: 'Для животных', path: `${Page.Categories}dlya-zhivotnyh-179/` },
    greenFriday: { id: '386', name: 'Зелёная пятница', path: `${Page.Categories}zelyonaya-pyatnitsa-386/` },
    groceries: { id: '18', name: 'Бакалея', path: `${Page.Categories}bakaleya-18/` },
    halal: { id: '216', name: 'Халяль', path: `${Page.Categories}halyal-216/` },
    halloween: { id: '205', name: 'Halloween👻', path: `${Page.Categories}halloween-205/` },
    healthWeek: { id: '220', name: 'Здоровая неделя', path: `${Page.Categories}zdorovaya-nedelya-220/` },
    iherb: { id: '198', name: 'iHerb', path: `${Page.Categories}iherb-198/` },
    learning: { id: '229', name: 'Обучение', path: `${Page.Categories}obuchenie-229/` },
    liquidation: { id: '225', name: 'Ликвидация', path: `${Page.Categories}likvidatsiya-225/` },
    motherDay: { id: '389', name: 'День матери', path: `${Page.Categories}den-materi-389/`},
    newYearMiracle: { id: '210', name: 'НОВОГОДНЕЕ ЧУДО', path: `${Page.Categories}novogodnee-chudo-210/` },
    octoberFest: { id: '199', name: 'OctoberFest', path: `${Page.Categories}octoberfest-199/` },
    onFebruary23rd: { id: '94', name: 'На 23 февраля', path: `${Page.Categories}na-23-fevralya-94/` },
    ourProducts: { id: '183', name: 'Наши продукты', path: `${Page.Categories}nashi-produkty-183/` },
    pancakeWeek: { id: '217', name: 'Масленица', path: `${Page.Categories}maslenitsa-217/` },
    peptides: { id: '227', name: 'Пептиды', path: `${Page.Categories}peptidy-227/` },
    // sale1111: { id: '208', name: 'РАСПРОДАЖА 11.11', path: `${Page.Categories}rasprodazha-1111-208/` },
    sale1111: { id: '388', name: 'РАСПРОДАЖА 11.11', path: `${Page.Categories}1111-vsemirnyy-den-rasprodazh-388/` },
    schoolFriday: { id: '196', name: 'ШКОЛЬНАЯ ПЯТНИЦА', path: `${Page.Categories}shkolnaya-pyatnitsa-196/` },
    sportWeek: { id: '222', name: 'Неделя спорта', path: `${Page.Categories}nedelya-sporta-222/` },
    summerEnergy: { id: '226', name: 'Энергия лета', path: `${Page.Categories}energiya-leta-226/` },
    sunnySummer: { id: '224', name: 'Солнечное лето', path: `${Page.Categories}solnechnoe-leto-224/` },
    sweetGifts: { id: '190', name: 'Сладкие подарки', path: `${Page.Categories}sladkie-podarki-190/` },
    sweetLife: { id: '218', name: 'Сладкая жизнь', path: `${Page.Categories}sladkaya-zhizn-218/` },
    thisWeekDiscounts: { id: '89', name: 'Скидки этой недели', path: `${Page.Categories}skidki-etoy-nedeli-89/` },
    valentinesDay: { id: '212', name: 'ДЕНЬ ВЛЮБЛЁННЫХ', path: `${Page.Categories}den-vlyublyonnyh-212/` },
    womensWeek: { id: '215', name: 'Women\'s Week', path: `${Page.Categories}womens-week-215/` }
  },
  confirmDeletionTexts: {
    address: 'Удалить адрес?',
    article: 'Удалить статью?',
    brand: 'Удалить бренд?',
    cartProduct: 'Удалить товар из корзины?',
    cartProducts: 'Удалить выбранные товары из\xA0корзины?',
    category: 'Удалить категорию?',
    certificate: 'Удалить сертификат?',
    collection: 'Удалить подборку?',
    list: 'Удалить список?',
    message: 'Удалить сообщение?',
    order: 'Удалить заказ?',
    productQuestion: 'Удалить вопрос о\xA0товаре?',
    product: 'Удалить товар?',
    promoCode: 'Удалить промокод?',
    recommendation: 'Удалить рекомендации?',
    review: 'Удалить отзыв о\xA0товаре?',
    specialOffer: 'Удалить акцию?',
    specialist: 'Удалить специалиста?',
    supplier: 'Удалить поставщика?',
    supplierFaq: 'Удалить FAQ?',
    tag: 'Удалить тег?',
    test: 'Удалить тест?',
    user: 'Удалить пользователя?',
    warehouse: 'Удалить склад?'
  },
  cookieOptions: { maxAge: 31536000, path: '/' }, // 31536000 seconds is 365 days.
  dateTimeFormats: { // @see https://date-fns.org/v4.1.0/docs/format
    dateFull: 'd MMMM yyyy',
    dateInput: 'dd.MM.yyyy',
    dateOutput: 'yyyy-MM-dd',
    dateShort: 'd MMM',

    dateTimeFull: 'd MMM yyyy, HH:mm',
    dateTimeInput: 'dd.MM.yyyy HH:mm:ss',
    dateTimeOutput: 'yyyy-MM-dd\'T\'HH:mm:ss'
  },
  defaultWeightInGrams: 1000,
  documentUrls: {
    agencyAgreement: `${cdnUrl}/documents/agency-agreement.pdf`,
    consultationAgencyContract: `${cdnUrl}/documents/consultation/agency-contract.doc`,
    consultationDataAgreement: `${cdnUrl}/documents/consultation/data-agreement.docx`,
    consultationInfoAgreement: `${cdnUrl}/documents/consultation/info-agreement.docx`,
    consultationOffer: `${cdnUrl}/documents/consultation/offer.docx`,
    consultationPrivacyPolicy: `${cdnUrl}/documents/consultation/privacy-policy.docx`,
    consultationServiceAgreement: `${cdnUrl}/documents/consultation/service-agreement.docx`,
    consultationUserAgreement: `${cdnUrl}/documents/consultation/user-agreement.docx`,
    coupons: `${cdnUrl}/documents/coupons.pdf`,
    dataProtectionPolicy: `${cdnUrl}/documents/data-protection-policy.pdf`,
    marketingCases: `${cdnUrl}/documents/marketing-cases.pdf`,
    marketingTools: `${cdnUrl}/documents/marketing-tools.pdf`,
    offerForSuppliers: `${cdnUrl}/documents/offer-for-suppliers.pdf`,
    serviceAgreement: `${cdnUrl}/documents/service-agreement.pdf`,
    shippingList: `${cdnUrl}/documents/shipping-list.docx`,
    supplyRequest: `${cdnUrl}/documents/supply-request.xlsx`,
    userAgreement: `${cdnUrl}/documents/user-agreement.pdf`,
  },
  editorConfig: {},
  fivePostWidgetApiKey: '60c1842e-1d1a-48ae-8696-9ea13f41c871',
  galleryOptions: {
    breakpoints: {
      1200: { slidesPerGroup: 1, slidesPerView: 5 },
      1020: { slidesPerGroup: 1, slidesPerView: 3 },
      360: { slidesPerGroup: 1, slidesPerView: 2.3, spaceBetween: 8 },
      0: { slidesPerGroup: 1, slidesPerView: 1.15, spaceBetween: 8 }
    },
    lazy: true,
    spaceBetween: 16
  } as SwiperOptions,
  galleryOptions2: {
    breakpoints: {
      1200: { slidesPerGroup: 1, slidesPerView: 4 },
      1020: { slidesPerGroup: 1, slidesPerView: 3 },
      360: { slidesPerGroup: 1, slidesPerView: 2.3, spaceBetween: 8 },
      0: { slidesPerGroup: 1, slidesPerView: 1.15, spaceBetween: 8 }
    },
    lazy: true,
    spaceBetween: 16
  } as SwiperOptions,
  isDolyameEnabled: true,
  minOrderSubtotalAmountInKopecks: 100, // 100 kopecks is 1 ruble.
  moscowDivisions: [
    'Воскресенское п',
    'Зеленоград г',
    'Коммунарка п',
    'Москва г',
    'Московский г',
    'Троицк г',
    'Щербинка г'
  ],
  paidUtmSources: ['admitad', 'cityads', 'dzen', 'instagram', 'odnoklassniki', 'vkontakte', 'yd'],
  partnerTelegramChannelUrl: 'https://t.me/+8Qnl3QguPgYwN2Ni',
  phoneNumbers: {
    // mainWarehouse: { formattedText: '+7\xA0(925)\xA0959-25-68', href: 'tel:+79259592568' },
    mainWarehouse: { formattedText: '8\xA0(800)\xA0775-46-17', href: 'tel:88007754617' },
    support: { formattedText: '8\xA0(800)\xA0775-46-17', href: 'tel:88007754617' }
  },
  podcastsUrl: 'https://nazdorovoivolne.mave.digital',
  siteUrl: 'https://itab.pro',
  storageBaseUrl,
  supportEmail: 'info@itab.pro',
  supportTelegramUrl: 'https://t.me/iTAB_zabota_bot', // https://t.me/zabota_itab. Not used.
  supportWhatsAppUrl: 'https://wa.me/79958808658', // https://wa.me/79959000512. Not used.
  testsUrl: 'https://tests.itab.pro',
  utmParameters: {
    maamCpcCard: {
      listId: 'a57d2f0b-acc6-4bb3-a972-d50445e26ddd',
      utmCampaign: 'card',
      utmMedium: 'cpc',
      utmSource: 'maam'
    },
    maamCpcMarket: {
      productIds: ['1651', '2659', '2678', '2679', '3020', '3031'],
      utmCampaign: 'market',
      utmMedium: 'cpc',
      utmSource: 'maam'
    }
  },
  wordForms: {
    character: ['символ', 'символа', 'символов'],
    day: ['день', 'дня', 'дней'],
    hour: ['час', 'часа', 'часов'],
    left: ['Осталась', 'Осталось', 'Осталось'],
    minute: ['минута', 'минуты', 'минут'],
    piece: ['штука', 'штуки', 'штук'],
    point: ['балл', 'балла', 'баллов'],
    pointInGenitiveCase: ['балла', 'баллов', 'баллов'],
    product: ['товар', 'товара', 'товаров'],
    second: ['секунда', 'секунды', 'секунд'],
    try: ['попытка', 'попытки', 'попыток'],
    workingDay: ['рабочий день', 'рабочих дня', 'рабочих дней'],
    year: ['год', 'года', 'лет']
  },
  yandexDelivery: {
    sourcePlatformStationId: '88346cdc-4c6b-67d1-2704-3393fff43bdd'
  }
}
