import {defineStore} from 'pinia'

import SearchboosterSort from '~/enums/searchbooster-sort'
import YandexMetrikaGoal from '~/enums/yandex-metrika-goal'
import YandexMetrikaService from '~/services/yandex-metrika-service'
import {searchboosterSearchboxAdapter} from '~/store/compositions/actions/searchbooster-searchbox-adapter'
import type {ProductData} from '~/types/products'
import type {
  SearchboosterCompletions,
  SearchboosterOffer,
  SearchboosterSearchBoxItem,
  SearchboosterSearchResponse
} from '~/types/searchbooster'

export const messages = {
  failedToGetCompletions: 'Не удалось найти подсказки.',
  failedToGetPopularProducts: 'Не удалось найти популярные товары.',
  failedToGetProducts: 'Не удалось найти товары.'
}

type State = {
  completions: SearchboosterCompletions | null
  filter: {
    categoryId: string | null
    limit: number
    params: {
      categories: string[],
      isExpiring: boolean
      isRecommended: boolean
      price: {
        min: number | null
        max: number | null
      }
      vendors: string[]
    }
    skip: number
    query: string | null
  }
  isLoading: boolean
  isSearchLoading: boolean
  lastUpdated: string | null
  popularOffers: SearchboosterOffer[]
  searchMeta: SearchboosterSearchResponse | null
  searchProductsData: ProductData[]
  sort: string
  temp: {
    categoriesMap: Record<string, string>
    vendorsMap: Record<string, string>
  }
}

const initialState = (): State => ({
  completions: null,
  filter: {
    categoryId: null,
    limit: 12,
    params: {
      categories: [],
      isExpiring: false,
      isRecommended: false,
      price: {
        min: null,
        max: null
      },
      vendors: []
    },
    skip: 0,
    query: ''
  },
  isLoading: false,
  isSearchLoading: false,
  lastUpdated: null,
  popularOffers: [],
  searchMeta: null,
  searchProductsData: [],
  sort: SearchboosterSort.Popular,
  temp: {
    categoriesMap: {},
    vendorsMap: {}
  }
})

export const useSearchboosterStore = defineStore('searchbooster', {
  state: () => initialState(),
  getters: {
    getCompletions: (state: State): SearchboosterSearchBoxItem[] => (state.completions?.searchBox || []),
    getCompletionsBrands (): SearchboosterSearchBoxItem[] {
      return this.getCompletions.filter(item => item.label === 'brands') || []
    },
    getCompletionsCategories (): SearchboosterSearchBoxItem[] {
      return this.getCompletions.filter(item => item.label === 'categories') || []
    },
    getCompletionsExtraOffers (): SearchboosterSearchBoxItem[] {
      return this.getCompletions.filter(item => item.label === 'extra_offer') || []
    },
    getCountFilters (state: State): number {
      return state.filter.params.categories.length + state.filter.params.vendors.length
    },
    getHistory (): SearchboosterSearchBoxItem[] {
      return this.getCompletions.filter(item => item.label === 'history') || []
    },
    getMaxPrice (): number | null {
      return this.searchMeta?.params.find((x: any) => x.id === 'price')?.maxValue || null
    },
    getMinPrice (): number | null {
      return this.searchMeta?.params.find((x: any) => x.id === 'price')?.minValue || null
    },
    getOffers (): SearchboosterSearchBoxItem[] {
      return this.getCompletions.filter(item => item.label === 'offers') || []
    },
    getPopularOffers: (state: State) => state.popularOffers,
    getPopularTips (): SearchboosterSearchBoxItem[] {
      return this.getCompletions.filter(item => item.label === 'popular') || []
    },
    getSearchOffers: (state: State) => state.searchMeta?.offers || [],
    getSearchProducts: (state: State): ProductData[] => state.searchProductsData,
    getTips (): SearchboosterSearchBoxItem[] {
      return this.getCompletions.filter(item => item.label === 'suggestions') || []
    },
    isActiveFilter: (state: State) => {
      const initialFilter = initialState().filter

      return JSON.stringify(state.filter) !== JSON.stringify(initialFilter)
    },
    isRecentUpdate: (state): boolean => {
      if (!state.lastUpdated) {
        return false
      }

      const lastUpdatedTime = new Date(state.lastUpdated).getTime()
      const currentTime = Date.now()
      const differenceInMinutes = (currentTime - lastUpdatedTime) / (1000 * 60)

      return differenceInMinutes <= 5
    }
  },
  actions: {
    ...searchboosterSearchboxAdapter(),
    addFilterByCategory (categoryId: string, categoryName: string): void {
      if (!this.filter.params.categories.includes(categoryId)) {
        this.filter.params.categories.push(categoryId)
      }

      if (!this.temp.categoriesMap[categoryId]) {
        this.temp.categoriesMap[categoryId] = categoryName
      }
    },
    addFilterByVendor (vendorId: string, vendorName: string): void {
      if (!this.filter.params.vendors.includes(vendorId)) {
        this.filter.params.vendors.push(vendorId)
      }

      if (!this.temp.vendorsMap[vendorId]) {
        this.temp.vendorsMap[vendorId] = vendorName
      }
    },
    buildParams (): any[] {
      const {
        categories,
        isRecommended,
        isExpiring,
        price,
        vendors
      } = this.filter.params

      return [
        ...(isRecommended ? [{field: 'recommended', condition: 'in', options: ['true']}] : []),
        ...(!isExpiring ? [{field: 'isexpiring', condition: 'in', options: ['false']}] : []),
        ...(categories.length > 0 ? [{field: 'category', condition: 'in', options: categories}] : []),
        ...(price.min !== null || price.max !== null ? [{
          field: 'price',
          condition: 'range',
          min: price.min !== null ? price.min.toString() : undefined,
          max: price.max !== null ? price.max.toString() : undefined,
        }] : []),
        ...(vendors.length > 0 ? [{field: 'vendor', condition: 'in', options: vendors}] : [])
      ]
    },
    clearFilter (): void {
      const state = initialState()
      this.filter = state.filter
      this.temp = state.temp
    },
    clearFilterCats (): void {
      this.filter.params.categories = []
      this.temp.categoriesMap = {}
    },
    clearFilterVendors (): void {
      this.filter.params.vendors = []
      this.temp.vendorsMap = {}
    },
    clearSearchMeta (): void {
      this.searchMeta = null
    },
    clearSearchProducts (): void {
      this.searchProductsData = []
    },
    deleteFilterByCategoryId (categoryId: string): void {
      const i = this.filter.params.categories.indexOf(categoryId)

      if (i !== -1) {
        this.filter.params.categories.splice(i, 1)
      }

      if (this.temp.categoriesMap[categoryId]) {
        delete this.temp.categoriesMap[categoryId]
      }
    },
    deleteFilterByVendorId (vendorId: string): void {
      const i = this.filter.params.vendors.indexOf(vendorId)

      if (i !== -1) {
        this.filter.params.vendors.splice(i, 1)
      }

      if (this.temp.vendorsMap[vendorId]) {
        delete this.temp.vendorsMap[vendorId]
      }
    },
    nextProducts (): void {
      this.filter.skip += this.filter.limit
    },
    resetPagination (): void {
      this.filter.skip = 0
    },
    setLastUpdated() {
      this.lastUpdated = new Date().toISOString()
    },
    updateFilterFromQuery (query: any): void {
      this.filter.query = query.q || ''
      this.filter.categoryId = query.categoryId || null
      this.filter.params.categories = query.categories ? query.categories.split(',') : []
      this.filter.params.isExpiring = query.isExpiring === 'true'
      this.filter.params.isRecommended = query.isRecommended === 'true'
      this.filter.params.price.min = query.priceMin ? parseFloat(query.priceMin) : null
      this.filter.params.price.max = query.priceMax ? parseFloat(query.priceMax) : null
      this.filter.params.vendors = query.vendors ? query.vendors.split(',') : []

      this.temp.categoriesMap = query.categoriesMap ? JSON.parse(query.categoriesMap) : {}
      this.temp.vendorsMap = query.vendorsMap ? JSON.parse(query.vendorsMap) : {}
    },
    async fetchCompletions ({ signal }: { signal?: AbortSignal } = {}): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isLoading = true

      try {
        this.completions = await $apiHelper.searchbooster.getCompletions({
          query: this.filter.query,
        }, signal) as any
      } catch (error: any) {
        if (!signal || !signal.aborted) {
          console.error(error)
          $toast.error(messages.failedToGetCompletions)
        }
      } finally {
        this.isLoading = false
      }
    },
    async fetchPopularProducts ({ signal }: { signal?: AbortSignal } = {}): Promise<void> {
      const {$apiHelper, $toast} = useNuxtApp()

      this.isLoading = true

      try {
        const response = await $apiHelper.searchbooster.getPopularProducts({}, signal) as any
        this.popularOffers = response.offers

        if (this.popularOffers.length === 0) {
          YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.SearchNoResults)

          return
        }
      } catch (error: any) {
        if (!signal || !signal.aborted) {
          console.error(error)
          $toast.error(messages.failedToGetPopularProducts)
        }
      } finally {
        this.isLoading = false
      }
    },
    async fetchSearch ({ signal }: { signal?: AbortSignal } = {}): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      this.isSearchLoading = true

      const params: any[] = this.buildParams()

      try {
        this.searchMeta = await $apiHelper.searchbooster.getProducts({
          categoryId: this.filter.categoryId,
          limit: this.filter.limit,
          skip: this.filter.skip,
          sortBy: this.sort !== SearchboosterSort.PriceDesc ? this.sort : 'price',
          sortDest: this.sort !== SearchboosterSort.PriceDesc ? 'ASC' : 'desc',
          query: this.filter.query,
          params: JSON.stringify(params)
        }, signal) as any

        if (this.getSearchOffers.length === 0) {
          YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.SearchNoResults, { query: this.filter.query })

          return
        }

        for (let i = 0; i < this.getSearchOffers.length; i++) {
          const product = this.convertOfferToProductData(this.getSearchOffers[i])
          const exists = this.searchProductsData.some(x => x.id === product.id)

          if (!exists) {
            this.searchProductsData.push(product)
          }
        }

        this.setLastUpdated()
      } catch (error: any) {
        if (!signal || !signal.aborted) {
          console.error(error)
          $toast.error(messages.failedToGetProducts)
        }
      } finally {
        this.isSearchLoading = false
      }
    },
    async initCompletionsByQuery (x: SearchboosterSearchBoxItem): Promise<void> {
      this.clearFilter()
      this.filter.query = x.search.query

      await this.fetchCompletions()

      YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.Search, { query: x.search.query })
    },
    async initSearch (resetPagination: boolean = true): Promise<void> {
      this.clearSearchMeta()
      this.clearSearchProducts()

      if (resetPagination) {
        this.resetPagination()
      }

      await this.fetchSearch()
      await this.fetchCompletions()

      YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.Search)
    },
    async initSearchByQuery (x: SearchboosterSearchBoxItem): Promise<void> {
      this.clearFilter()
      this.filter.query = x.search.query
      this.clearSearchProducts()
      this.resetPagination()

      await this.fetchSearch()
      await this.fetchCompletions()

      YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.Search, { query: x.search.query })
    },
    async paginate (): Promise<void> {
      this.nextProducts()

      await this.fetchSearch()

      YandexMetrikaService.sendMetricEvent(YandexMetrikaGoal.Search)
    }
  }
})
