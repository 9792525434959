import { defineStore } from 'pinia'

import constants from '~/constants'
import type OrderPaymentMethod from '~/enums/order-payment-method'
import OrderShippingType from '~/enums/order-shipping-type'
import helpers from '~/helpers'
import ApiErrorHandler from '~/helpers/api-error-handler'
import JsonApiHelper from '~/helpers/json-api-helper'
import EcommerceApiService from '~/services/ecommerce-api-service'
import { useProductsStore } from '~/store/products'
import type {
  CartProductData,
  EcommerceCartItem, GetCartProductsParams,
  Gift,
  Notification,
  OrderPaymentMethod as CartOrderPaymentMethod,
  OrderShippingType as CartOrderShippingType
} from '~/types/cart'
import type { ShippingData, UserShippingData } from '~/types/shipping'

type Form = {
  // Agreements.
  isAgreeToPreOrder: boolean
  isAgreeToTermsAndConditions: boolean

  // Discounts.
  certificateName: string | null
  pointsToSpend: number | null
  promoCodeName: string | null
  superminsPointsToSpend: number | null

  // Shipping.
  comment: string | null
  shippingAddressLine1: string | null
  shippingAddressLine2: string | null
  shippingCity: string | null
  shippingCountry: string | null
  shippingEmail: string | null
  shippingFirstName: string | null
  shippingLastName: string | null
  shippingPhoneNumber: string | null
  shippingPostcode: string | null
  shippingState: string | null
}

type State = {
  backToSchoolStepAmountInKopecks: number | null
  cartProductsData: CartProductData[]
  discountAmountInKopecks: number | null
  errorCode: string | null
  errorDetail: string | null
  form: Form
  gifts: Gift[]
  goldenTicketStepAmountInKopecks: number | null
  isCertificateApplied: boolean
  isCertificateApplying: boolean
  isDataLoading: boolean
  isDataLoading2: boolean
  isDataLoadingError: boolean
  isFreeShippingAllowed: boolean | null
  isFreeShippingOnFirstOrderAllowed: boolean | null
  isFreeShippingOnFirstOrderEnabled: boolean | null
  isOrderCreating: boolean
  isPointsApplying: boolean
  isPromoCodeApplied: boolean
  isPromoCodeApplying: boolean
  notifications: Notification[]
  orderPaymentMethod: OrderPaymentMethod | null
  orderPaymentMethods: CartOrderPaymentMethod[]
  orderShippingType: OrderShippingType | null
  orderShippingTypes: CartOrderShippingType[]
  shippingCostInKopecks: number | null
  shippingData: ShippingData | null
  showCart: boolean
  urgentOrderPriceInKopecks: number | null
  userShippingData: UserShippingData | null
  weightInGrams: number | null

  // Order tags.
  isBloggerOrder: boolean
  isRepeatOrder: boolean
  isUrgentOrder: boolean

  // Points.
  arePointsApplied: boolean
  cashbackInPoints: number | null
  maxPointsForOrder: number | null
  userTotalPoints: number | null

  // Supermins points.
  areSuperminsPointsApplied: boolean
  cashbackInSuperminsPoints: number | null
  maxSuperminsPointsForOrder: number | null
  userTotalSuperminsPoints: number | null
}

const initialState = (): State => ({
  backToSchoolStepAmountInKopecks: null,
  cartProductsData: [],
  discountAmountInKopecks: null,
  errorCode: null,
  errorDetail: null,
  form: {
    // Agreements.
    isAgreeToPreOrder: false,
    isAgreeToTermsAndConditions: false,

    // Discounts
    certificateName: null,
    pointsToSpend: null,
    promoCodeName: null,
    superminsPointsToSpend: null,

    // Shipping.
    comment: null,
    shippingAddressLine1: null,
    shippingAddressLine2: null,
    shippingCity: null,
    shippingCountry: null,
    shippingEmail: null,
    shippingFirstName: null,
    shippingLastName: null,
    shippingPhoneNumber: null,
    shippingPostcode: null,
    shippingState: null
  },
  gifts: [],
  goldenTicketStepAmountInKopecks: null,
  isCertificateApplied: false,
  isCertificateApplying: false,
  isDataLoading: false,
  isDataLoading2: false,
  isDataLoadingError: false,
  isFreeShippingAllowed: false,
  isFreeShippingOnFirstOrderAllowed: false,
  isFreeShippingOnFirstOrderEnabled: false,
  isOrderCreating: false,
  isPointsApplying: false,
  isPromoCodeApplied: false,
  isPromoCodeApplying: false,
  notifications: [],
  orderPaymentMethod: null,
  orderPaymentMethods: [],
  orderShippingType: null,
  orderShippingTypes: [],
  shippingCostInKopecks: null,
  shippingData: null,
  showCart: true,
  urgentOrderPriceInKopecks: null,
  userShippingData: null,
  weightInGrams: null,

  // Order tags.
  isBloggerOrder: false,
  isRepeatOrder: false,
  isUrgentOrder: false,

  // Points.
  arePointsApplied: false,
  cashbackInPoints: null,
  maxPointsForOrder: null,
  userTotalPoints: null,

  // Supermins points.
  areSuperminsPointsApplied: false,
  cashbackInSuperminsPoints: null,
  maxSuperminsPointsForOrder: null,
  userTotalSuperminsPoints: null
})

const messages = {
  // Promo code.
  failedToApplyPromoCode: 'Не удалось применить промокод.',
  promoCodeIsApplied: 'Промокод применён.',
  promoCodeIsCanceled: 'Промокод отменён.',

  // Points.
  redemptionOfPointsHasBeenCanceled: 'Списание баллов iTAB Плюс отменено.',
  redemptionOfSuperminsPointsHasBeenCanceled: 'Списание баллов Supermins отменено.',

  // Certificate.
  certificateIsCanceled: 'Сертификат отменён.',

  // Validation.
  countryIsNotSelected: 'Необходимо выбрать страну.',
  failedToGetCartProducts: 'Не удалось получить товары корзины.',
  localPickUpPointIsNotSelected: 'Необходимо выбрать пункт самовывоза.',
  orderSubtotalAmountIsTooSmall: 'Сумма товаров в заказе слишком мала.',
  addressIsNotFound: 'Извините, адрес не найден.',
  addressIsNotSelected: 'Необходимо выбрать адрес.',
  pickUpPointIsNotSelected: 'Необходимо выбрать пункт выдачи заказов.',
  shippingTypeIsNotSelected: 'Способ получения не выбран.',
  someProductsAreOnlyAvailableInMoscow: 'Некоторые товары доставляются только по Москве. Оформить заказ можно только после изменения города доставки или их удаления из корзины.'
}

export const useCartStore = defineStore('cart', {
  state: () => initialState(),
  getters: {
    areSomeProductsOnlyAvailableInMoscow (state: State): boolean {
      return state.cartProductsData.some((x: CartProductData): boolean => x.attributes.isOnlyAvailableInMoscow)
    },
    backToSchoolTicketsCount (state: State): number | null {
      if (state.backToSchoolStepAmountInKopecks === null || state.backToSchoolStepAmountInKopecks === 0) {
        return null
      }

      return Math.floor(this.totalInKopecks / state.backToSchoolStepAmountInKopecks)
    },
    finalShippingCostInKopecks (state: State): number | null {
      if (this.isFreeShippingEnabled) {
        return 0
      }

      return this.fixedShippingCostInKopecks ?? state.shippingCostInKopecks
    },
    fixedShippingCostInKopecks (state: State): number | null {
      const orderShippingType = state.orderShippingTypes.find(
        (x: CartOrderShippingType): boolean => x.id === state.orderShippingType
      )

      return orderShippingType && typeof orderShippingType.shippingCostInKopecks === 'number'
        ? orderShippingType.shippingCostInKopecks
        : null
    },
    getCartProductDataById: (state: State) => (cartProductId: string): CartProductData | null => {
      return state.cartProductsData.find(({ id }): boolean => id === cartProductId) || null
    },
    goldenTicketsCount (state: State): number | null {
      if (state.goldenTicketStepAmountInKopecks === null || state.goldenTicketStepAmountInKopecks === 0) {
        return null
      }

      return Math.floor(this.totalInKopecks / state.goldenTicketStepAmountInKopecks)
    },
    preOrderCartProductsData (state: State): CartProductData[] {
      return state.cartProductsData.filter((x: CartProductData): boolean => Boolean(x.meta.isOnPreOrder))
    },
    isFreeShippingEnabled (): boolean {
      return this.isFreeShippingEnabledByPromoCode || this.isFreeShippingEnabledBySubtotal
    },
    isFreeShippingEnabledByPromoCode (state: State): boolean {
      return state.orderShippingType !== null
        && OrderShippingType.isDeliveryToPickUpPoint(state.orderShippingType)
        && (
          Boolean(state.isFreeShippingOnFirstOrderEnabled)
          || (Boolean(state.isFreeShippingAllowed) && !state.isFreeShippingOnFirstOrderAllowed)
        )
    },
    isFreeShippingEnabledBySubtotal (): boolean {
      return this.minOrderSubtotalAmountInKopecksForFreeShipping !== null
        && this.subtotalWithDiscountInKopecks >= this.minOrderSubtotalAmountInKopecksForFreeShipping
    },
    isShippingCostKnown (): boolean {
      return this.isFreeShippingEnabled || this.fixedShippingCostInKopecks !== null
    },
    minOrderSubtotalAmountInKopecksForFreeShipping (state: State): number | null {
      const orderShippingType = state.orderShippingTypes.find(
        (x: CartOrderShippingType): boolean => x.id === state.orderShippingType
      )

      return orderShippingType && typeof orderShippingType.minOrderSubtotalAmountInKopecksForFreeShipping === 'number'
        ? orderShippingType.minOrderSubtotalAmountInKopecksForFreeShipping
        : null
    },
    subtotalInKopecks (state: State): number {
      return state.cartProductsData.reduce(
        (a: number, x: CartProductData): number => a
          + x.meta.quantity
          * (helpers.convertRublesToKopecks(helpers.getProductPrice(x)) ?? 0),
        0
      )
    },
    subtotalWithDiscountInKopecks (state: State): number {
      return this.subtotalInKopecks - (state.discountAmountInKopecks ?? 0)
    },
    totalInKopecks (state: State): number {
      return this.subtotalWithDiscountInKopecks
        + (this.finalShippingCostInKopecks ?? 0)
        + (state.isUrgentOrder && state.urgentOrderPriceInKopecks !== null ? state.urgentOrderPriceInKopecks : 0)
    },
    totalItems (state: State): number {
      return state.cartProductsData.reduce((a: number, x: CartProductData): number => a + x.meta.quantity, 0)
    }
  },
  actions: {
    checkOut (): void {
      const { $toast } = useNuxtApp()

      const { LocalPickUp, RussianPostCourier, RussianPostInternationalParcel } = OrderShippingType
      const {
        addressIsNotFound,
        addressIsNotSelected,
        countryIsNotSelected,
        localPickUpPointIsNotSelected,
        orderSubtotalAmountIsTooSmall,
        pickUpPointIsNotSelected,
        shippingTypeIsNotSelected,
        someProductsAreOnlyAvailableInMoscow
      } = messages

      const showErrorAndExit = (message: string): void => {
        $toast.error(message)
      }

      for (const { isError, text } of this.notifications) {
        if (isError !== undefined && text !== undefined && isError && text) {
          return showErrorAndExit(text)
        }
      }

      if (this.orderShippingType === null) {
        return showErrorAndExit(shippingTypeIsNotSelected)
      }

      if (OrderShippingType.isDeliveryToPickUpPoint(this.orderShippingType) && !this.shippingData) {
        return showErrorAndExit(pickUpPointIsNotSelected)
      }

      if (this.orderShippingType === LocalPickUp && !this.shippingData) {
        return showErrorAndExit(localPickUpPointIsNotSelected)
      }

      if (this.orderShippingType === RussianPostCourier) {
        if (!this.shippingData) {
          return showErrorAndExit(addressIsNotSelected)
        }

        if (this.shippingCostInKopecks === null) {
          return showErrorAndExit(addressIsNotFound)
        }
      }

      if (this.orderShippingType === RussianPostInternationalParcel && !this.shippingData) {
        return showErrorAndExit(countryIsNotSelected)
      }

      if (
        this.areSomeProductsOnlyAvailableInMoscow
        && OrderShippingType.isDeliveryToMoscow(this.orderShippingType, this.shippingData) === false
      ) {
        return showErrorAndExit(someProductsAreOnlyAvailableInMoscow)
      }

      if (this.subtotalWithDiscountInKopecks < constants.minOrderSubtotalAmountInKopecks) {
        return showErrorAndExit(orderSubtotalAmountIsTooSmall)
      }

      this.showCart = false
    },
    async fetchCartProducts (): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()
      const productsStore = useProductsStore()

      const {
        certificateIsCanceled,
        failedToApplyPromoCode,
        failedToGetCartProducts,
        promoCodeIsApplied,
        promoCodeIsCanceled,
        redemptionOfPointsHasBeenCanceled,
        redemptionOfSuperminsPointsHasBeenCanceled
      } = messages

      try {
        const response = await $apiHelper.cartProducts.getCartProducts(this.getParams())
        const { data, meta } = JsonApiHelper.denormalizeResponse(response)

        // Products.
        this.cartProductsData = data.map((x: CartProductData): CartProductData => {
          x.meta.isSelected = true

          return x
        })

        productsStore.setCartTotalItems(this.totalItems)
        productsStore.clearCartProducts()

        for (const { id, meta } of data) {
          productsStore.addCartProduct({ id, quantity: meta.quantity })
        }

        // Discounts.
        this.discountAmountInKopecks = meta.discountAmountInKopecks
        this.errorCode = meta.errorCode
        this.errorDetail = meta.errorDetail

        if (this.errorCode || this.errorDetail) {
          if (this.isPromoCodeApplying) {
            if (this.cartProductsData.length) {
              if (this.errorDetail) {
                $toast.error(this.errorDetail)
              } else if (this.errorCode) {
                $toast.error(ApiErrorHandler.getMessage(this.errorCode, failedToApplyPromoCode))
              }
            }
          } else if (this.isPromoCodeApplied) {
            this.isPromoCodeApplied = false

            $toast.warning(promoCodeIsCanceled)
          }

          if (this.arePointsApplied) {
            this.arePointsApplied = false

            $toast.warning(redemptionOfPointsHasBeenCanceled)
          }

          if (this.areSuperminsPointsApplied) {
            this.areSuperminsPointsApplied = false

            $toast.warning(redemptionOfSuperminsPointsHasBeenCanceled)
          }

          if (this.isCertificateApplied) {
            this.isCertificateApplied = false

            $toast.warning(certificateIsCanceled)
          }
        } else if (this.isPromoCodeApplying) {
          this.isPromoCodeApplied = true

          $toast.success(promoCodeIsApplied)
        }

        // Points.
        this.cashbackInPoints = typeof meta.cashbackInPoints === 'number' ? meta.cashbackInPoints : null
        this.maxPointsForOrder = typeof meta.maxPointsForOrder === 'number' ? meta.maxPointsForOrder : null
        this.userTotalPoints = typeof meta.userTotalPoints === 'number' ? meta.userTotalPoints : null

        // Supermins points.
        this.cashbackInSuperminsPoints = typeof meta.cashbackInSuperminsPoints === 'number'
          ? meta.cashbackInSuperminsPoints
          : null
        this.maxSuperminsPointsForOrder = typeof meta.maxSuperminsPointsForOrder === 'number'
          ? meta.maxSuperminsPointsForOrder
          : null
        this.userTotalSuperminsPoints = typeof meta.userTotalSuperminsPoints === 'number'
          ? meta.userTotalSuperminsPoints
          : null

        this.backToSchoolStepAmountInKopecks = typeof meta.backToSchoolStepAmountInKopecks === 'number'
          ? meta.backToSchoolStepAmountInKopecks
          : null
        this.gifts = Array.isArray(meta.gifts)
          ? meta.gifts.map((x: Gift): Gift => ({
            id: x.id,
            maxSubtotalInKopecks: x.maxSubtotalInKopecks ?? Infinity,
            minSubtotalInKopecks: x.minSubtotalInKopecks ?? 0,
            name: x.name
          }))
          : []
        this.goldenTicketStepAmountInKopecks = typeof meta.goldenTicketStepAmountInKopecks === 'number'
          ? meta.goldenTicketStepAmountInKopecks
          : null
        this.isFreeShippingAllowed = meta.isFreeShippingAllowed
        this.isFreeShippingOnFirstOrderAllowed = meta.isFreeShippingOnFirstOrderAllowed
        this.isFreeShippingOnFirstOrderEnabled = meta.isFreeShippingOnFirstOrderEnabled

        if (meta.notifications && Array.isArray(meta.notifications)) {
          this.notifications = meta.notifications
        }

        if (
          this.maxPointsForOrder !== null
          && (
            this.form.pointsToSpend === 0
            || (this.form.pointsToSpend !== null && this.form.pointsToSpend > this.maxPointsForOrder)
          )
        ) {
          this.form.pointsToSpend = this.maxPointsForOrder
        }

        if (
          this.maxSuperminsPointsForOrder !== null
          && (
            this.form.superminsPointsToSpend === 0
            || (
              this.form.superminsPointsToSpend !== null
              && this.form.superminsPointsToSpend > this.maxSuperminsPointsForOrder
            )
          )
        ) {
          this.form.superminsPointsToSpend = this.maxSuperminsPointsForOrder
        }

        if (meta.orderPaymentMethods && Array.isArray(meta.orderPaymentMethods)) {
          this.orderPaymentMethods = meta.orderPaymentMethods
        }

        if (meta.orderShippingTypes && Array.isArray(meta.orderShippingTypes)) {
          this.orderShippingTypes = meta.orderShippingTypes
        }

        if (meta.userShippingData) {
          this.userShippingData = meta.userShippingData
        }

        this.urgentOrderPriceInKopecks = typeof meta.urgentOrderPriceInKopecks === 'number'
          ? meta.urgentOrderPriceInKopecks
          : null

        if (this.urgentOrderPriceInKopecks === null) {
          this.isUrgentOrder = false
        }

        this.weightInGrams = meta.weightInGrams

        this.isDataLoadingError = false
      } catch (error) {
        console.error(error)

        this.isDataLoadingError = true

        $toast.error(failedToGetCartProducts)
      }
    },
    getParams (): GetCartProductsParams {
      const params: GetCartProductsParams = {}

      if (this.form.certificateName && (this.isCertificateApplied || this.isCertificateApplying)) {
        params.certificateName = this.form.certificateName
      }

      if (this.form.pointsToSpend !== null && (this.arePointsApplied || this.isPointsApplying)) {
        params.pointsToSpend = this.form.pointsToSpend
      }

      if (this.form.superminsPointsToSpend !== null && (this.areSuperminsPointsApplied || this.isPointsApplying)) {
        params.superminsPointsToSpend = this.form.superminsPointsToSpend
      }

      if (this.form.promoCodeName && (this.isPromoCodeApplied || this.isPromoCodeApplying)) {
        params.promoCodeName = this.form.promoCodeName
      }

      return params
    },
    goBackToCart (): void {
      this.showCart = true
    },
    trackCartByEcommerce (): void {
      if (!this.cartProductsData.length) {
        return
      }

      const ecommerceCartItems = []

      for (const x of this.cartProductsData) {
        const { attributes, id, meta } = x
        const { name } = attributes
        const { brandName, categoryNames, quantity } = meta
        const discountedPrice = helpers.getProductDiscountedPrice(x) ?? 0

        const ecommerceCartItem: EcommerceCartItem = { item_id: id, item_name: name, price: discountedPrice, quantity }

        if (typeof brandName === 'string') {
          ecommerceCartItem.item_brand = brandName
        }

        if (categoryNames && Array.isArray(categoryNames)) {
          ecommerceCartItem.item_category = categoryNames.sort()
        }

        ecommerceCartItems.push(ecommerceCartItem)
      }

      const total = helpers.convertKopecksToRubles(this.totalInKopecks)!

      if (this.showCart) {
        EcommerceApiService.viewCart(ecommerceCartItems, total)
      } else {
        EcommerceApiService.beginCheckout(ecommerceCartItems, total, this.form.promoCodeName)
      }
    }
  }
})
