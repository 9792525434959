import type { StatusColor } from '~/types'

enum ProductStatus {
  Published = '1',
  Draft = '2',
  Hidden = '3',
  Trash = '4'
}

const { Draft, Hidden, Published, Trash } = ProductStatus

const colorMapping: Map<ProductStatus, StatusColor> = new Map([
  [Published, 'green'],
  [Draft, 'gray'],
  [Hidden, 'orange'],
  [Trash, 'red']
])

const nameMapping = new Map([
  [Published, 'Опубликован'],
  [Draft, 'Черновик'],
  [Hidden, 'Скрыт'],
  [Trash, 'Удалён']
])

namespace ProductStatus {
  export const sortedMembers = [Published, Hidden, Draft, Trash]

  export function getColor (productStatus: ProductStatus): StatusColor {
    return colorMapping.get(productStatus)!
  }

  export function getName (productStatus: ProductStatus): string {
    return nameMapping.get(productStatus)!
  }
}

export default ProductStatus
