import HttpFactory from '~/api/http-factory'
import type { GetPopupsResponse } from '~/types/popups'

export default class Popups extends HttpFactory {
  private readonly resource = 'v1/popups'

  public getPopups (): Promise<GetPopupsResponse> {
    return this.$fetch(this.resource)
  }
}
