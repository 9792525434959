import HttpFactory from '~/api/http-factory'

export default class Searchbooster extends HttpFactory {
  private readonly timeout: number = 10
  private readonly url: string = 'https://api4.searchbooster.io/api/'

  public getCompletions (params: object, signal?: AbortSignal): Promise<any> {
    const completionSettings = {
      limits: {
        brands: 3,
        categories: 3,
        history: 3,
        offers: 4,
        popular: 5,
        special_offers: 3,
        suggestions: 3
      },
      orders: [
        'popular',
        'categories',
        'brands',
        'history',
        'suggestions',
        'special_offers',
        'offers'
      ],
      totalCompletionCount: 15
    }

    return this.$fetch(
      this.getUrl('completions'),
      { ...this.getFetchOptions(), params: { ...params, completionSettings }, signal }
    )
  }

  public getPopularProducts (params: object, signal?: AbortSignal): Promise<any> {
    return this.$fetch(
      this.getUrl('popular'),
      { ...this.getFetchOptions(), params: { ...params, limit: 8 }, signal }
    )
  }

  public getProducts (params: object, signal?: AbortSignal): Promise<any> {
    return this.$fetch(this.getUrl('search'), { ...this.getFetchOptions(), params, signal })
  }

  private getFetchOptions (): object {
    return {
      baseURL: this.url,
      headers: { Accept: 'application/json' },
      timeout: 100 * this.timeout,
    }
  }

  private getUrl (operation: 'completions' | 'popular' | 'search'): string {
    const config = useRuntimeConfig()

    return `${this.url}${config.public.searchboosterUUID}/${operation}/`
  }
}
