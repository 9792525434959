import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt3_plugin_WEl7CY_v1Ib6CurnBeaay9Kn1CyTVban9DBqxc0TPkk from "/usr/src/app/node_modules/vue-yandex-maps/dist/plugins/nuxt3-plugin.js";
import plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg from "/usr/src/app/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import api_helper_cni_LaUpgpjdqD17nB1lvQCyX_B7rrn1Fo2nyB7wC6E from "/usr/src/app/plugins/api-helper.ts";
import maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo from "/usr/src/app/plugins/maska.ts";
import pwa_update_client__pstVonA_vDBBMt4cZe9DSryx5rF7oTZkB2gK9qVeN8 from "/usr/src/app/plugins/pwa-update.client.ts";
import vue_final_modal_r3hWN1g3fjLRxtJ_rTg5dKijwBD5cViPOKcrr2biDj0 from "/usr/src/app/plugins/vue-final-modal.ts";
import vue_lazyload_client_s3gQqPvuDEiI7Nj2xh6GfxL9wH2mqs31bwUAkch_cxM from "/usr/src/app/plugins/vue-lazyload.client.ts";
import vue3_toastify_client_7NzB2SKUE19ktZgM2Q43E2Ej3jIAlOtZD5OOsRycyVo from "/usr/src/app/plugins/vue3-toastify.client.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  nuxt3_plugin_WEl7CY_v1Ib6CurnBeaay9Kn1CyTVban9DBqxc0TPkk,
  plugin_jjl2DFTrQxMG7TqNyE_rvcIV8r2uFVLO_Sius2B7lXg,
  api_helper_cni_LaUpgpjdqD17nB1lvQCyX_B7rrn1Fo2nyB7wC6E,
  maska_3Pdj_1ePE2_bvNiJO2MTIpupg59ALfbg8NtShv_Xmbo,
  pwa_update_client__pstVonA_vDBBMt4cZe9DSryx5rF7oTZkB2gK9qVeN8,
  vue_final_modal_r3hWN1g3fjLRxtJ_rTg5dKijwBD5cViPOKcrr2biDj0,
  vue_lazyload_client_s3gQqPvuDEiI7Nj2xh6GfxL9wH2mqs31bwUAkch_cxM,
  vue3_toastify_client_7NzB2SKUE19ktZgM2Q43E2Ej3jIAlOtZD5OOsRycyVo
]