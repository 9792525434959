enum YandexMetrikaGoal {
  AddToCart = 'add_to_cart',
  IndexBannersSectionBannerCardsSpecialists = 'index.banners-section.banner-cards.specialists',
  IndexBannersSectionBannerCardsTests = 'index.banners-section.banner-cards.tests',
  Search = 'search',
  SearchNoResults = 'search_no_results',
  SearchToCart = 'search_to_cart',
  ViewCart = 'view_cart',
  ViewCatalog = 'view_catalog',
  ViewProduct = 'view_product',
}

export default YandexMetrikaGoal
